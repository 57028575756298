import { CloseCircleOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import React from 'react'

const PreviewDoc = ({ setOpenPreview, doc }) => {
  return (
    <div style={{ position: 'relative' }}>
      <CloseCircleOutlined
        style={{
          position: 'absolute',
          bottom: '43rem',
          right: '8rem',
          zIndex: '1000',
          fontSize: '2.6em',
          color: '#fff',
        }}
        onClick={() => setOpenPreview(false)}
      />
      <Row
        style={{
          display: 'flex',
          position: 'fixed',
          justifyContent: 'center',
          top: 0,
          left: 0,
          background: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(4px)',
          width: '100%',
          height: '100%',
        }}
        onClick={() => setOpenPreview(false)}
      >
        <iframe
          src={`${doc}`}
          title="preview"
          style={{ width: '70%', height: '100%', objectFit: 'cover' }}
        ></iframe>
      </Row>
    </div>
  )
}

export default PreviewDoc
