import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Typography,
} from 'antd'
import { LockOutlined } from '@ant-design/icons'

//images
import Logo from '../../images/logo.svg'

//http
import { passwordReset } from '../../http/auth'

const ResetPassword = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values) => {
    setLoading(true)
    const data = {
      password: values.password,
      passwordConfirm: values.confirmPassword,
      resetCode: values.resetCode.toString(),
    }

    try {
      const res = await passwordReset(data)
      if (res.data?.data?.length > 0) {
        notification.success({
          message: 'Password Reset',
          description: `Password has been reset successfully.`,
        })

        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      return notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
      })
    }
  }

  return (
    <Row
      style={{
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        backgroundColor: '#F5F8F9',
      }}
    >
      <Col span={8}>
        <h2>Reset Password</h2>
        <p>Provide a new password for this account in order to log in.</p>
        <Form form={form} onFinish={handleSubmit}>
          <div style={{ margin: '3.5em 0' }}>
            <Form.Item name="password">
              <Input.Password
                prefix={
                  <>
                    <LockOutlined style={{ color: '#CBCECF' }} />
                  </>
                }
                placeholder="Password"
                className="input__group"
              />
            </Form.Item>
            <Form.Item name="confirmPassword">
              <Input.Password
                prefix={
                  <>
                    <LockOutlined style={{ color: '#CBCECF' }} />
                  </>
                }
                placeholder="Confirm Password"
                className="input__group"
              />
            </Form.Item>
            <Form.Item name="resetCode">
              <InputNumber
                placeholder="Reset Code"
                className="input__group"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>
          <Button
            type="primary"
            htmlType="submit"
            className="custom__primary__button"
            size="large"
            style={{ width: '100%' }}
            loading={loading}
          >
            Reset Password
          </Button>
          <p style={{ marginTop: '2em' }}>
            <Typography.Text>Back to</Typography.Text>{' '}
            <Link to="/login">
              <Typography.Text
                className="back__to"
                style={{ fontWeight: '500' }}
              >
                Log In
              </Typography.Text>{' '}
            </Link>
            screen
          </p>
        </Form>
      </Col>
      <div className="logo" style={{ left: '45%' }}>
        <p>
          Powered by <img src={Logo} alt="cmab" height="15px" />
        </p>
      </div>
    </Row>
  )
}

export default ResetPassword
