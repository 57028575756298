/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { notification } from 'antd'

//images
// import AttachFiles from "../../../images/attach.svg";
import Escalate from '../../../images/escalate.svg'
import Updates from '../../../images/update.svg'
import User from '../../../images/account.svg'

//components imports
import EscalateTicketModal from '../../Modals/Tickets/EscalateTicketModal'
import UpdateTicketModal from '../../Modals/Tickets/UpdateTicketModal'
import AssignTicketModal from '../../Modals/Tickets/AssignTicketModal'

//http
import { getStaffs } from '../../../http/staff'
import { getInsurers } from '../../../http/insurer'

//context
import { AuthContext } from '../../../context'

import { Roles } from '../../../models/enum'

//custom css
import '../BaseLayout.css'
import { StarFilled } from '@ant-design/icons'

const TicketRightSider = ({ setAssigned, setEscalated }) => {
  const { user, accessToken, selectedTicket } = useContext(AuthContext)

  const [isEscalateModalVisible, setIsEscalateModalVisible] = useState(false)
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false)
  const [isAssignModalVisible, setAssignModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const [companies, setCompanies] = useState([])
  const [tempCompanies, setTempCompanies] = useState([])
  const [staffs, setStaffs] = useState([])
  const [tempStaffs, setTempStaffs] = useState([])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)

      try {
        const res = await getStaffs(accessToken)
        if (res.data?.data) {
          setStaffs(res.data.data)
          setTempStaffs(res.data.data)
        }

        setLoading(false)
      } catch (error) {
        notification.error({
          message: 'Error',
          description: `${error.response.data.message}`,
        })
        setLoading(false)
      }
    }

    getData()
  }, [])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)

      try {
        const res = await getInsurers(accessToken)
        if (res.data?.data) {
          let displayCompanies = [...res.data.data]
            .filter(
              (company) =>
                company?.type !== selectedTicket?.company?.type &&
                company?.type !== 'INSURER' &&
                company?.type !== 'BROKER' &&
                company?.type !== 'AGENT',
            )
            .map((company) => {
              return {
                ...company,
                tier:
                  company?.type === 'GIA' || company?.type === 'CMAB'
                    ? 'TIER_2'
                    : 'TIER_3',
              }
            })
          setCompanies(displayCompanies)
          setTempCompanies(displayCompanies)
          setLoading(false)
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: `${error.response.data.message}`,
        })
        setLoading(false)
      }
    }

    getData()
  }, [])

  return (
    <>
      <div className="right__side__bar">
        {user?.role === Roles.admin && (
          <>
            {/* <div className="item">
              <img src={AttachFiles} alt="attach files" />
              <p>Attach files</p>
            </div> */}
            {(!selectedTicket?.assignedTo || selectedTicket?.assignedTo) &&  selectedTicket?.status !== 'SETTLED'  && (
              <>
                <div
                  className="item"
                  onClick={() => setIsEscalateModalVisible(true)}
                >
                  <img src={Escalate} alt="attach files" />
                  <p>Escalate</p>
                </div>
              </>
            )}

            {!selectedTicket?.assignedTo &&
              selectedTicket?.tier === user?.accessLevel && (
                <div
                  className="item"
                  onClick={() => setAssignModalVisible(true)}
                >
                  <img
                    src={User}
                    alt="assign icon"
                    style={{
                      backgroundColor: '#4B5F83',
                      borderRadius: '50%',
                      padding: '0.7em',
                    }}
                  />
                  <p>Assign</p>
                </div>
              )}
          </>
        )}
        <div className="item" onClick={() => setUpdateModalVisible(true)}>
          <img src={Updates} alt="attach files" />
          <p>Updates</p>
        </div>
        {selectedTicket?.customer?.rating && 
          <div>
              <StarFilled style={{color: "#FFDF00", fontSize: "2.8rem"}}/>
              <p style={{fontWeight: "bold", marginBottom: 0}}>{selectedTicket?.customer?.rating}</p>
              <p>Rating</p>
          </div>
        }
      </div>
      {isEscalateModalVisible && (
        <EscalateTicketModal
          isEscalateModalVisible={isEscalateModalVisible}
          setIsEscalateModalVisible={setIsEscalateModalVisible}
          loading={loading}
          setLoading={setLoading}
          staffs={staffs}
          setStaffs={setStaffs}
          tempStaffs={tempStaffs}
          companies={companies}
          setCompanies={setCompanies}
          tempCompanies={tempCompanies}
          setEscalated={setEscalated}
        />
      )}
      {isUpdateModalVisible && (
        <UpdateTicketModal
          isUpdateModalVisible={isUpdateModalVisible}
          setUpdateModalVisible={setUpdateModalVisible}
        />
      )}
      {isAssignModalVisible && (
        <AssignTicketModal
          isAssignModalVisible={isAssignModalVisible}
          setAssignModalVisible={setAssignModalVisible}
          staffs={staffs}
          setStaffs={setStaffs}
          tempStaffs={tempStaffs}
          setTempStaffs={setTempStaffs}
          loading={loading}
          setLoading={setLoading}
          id={selectedTicket?.id}
          accessToken={accessToken}
          setAssigned={setAssigned}
          user={user}
        />
      )}
    </>
  )
}

export default TicketRightSider
