import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Form, Input, notification, Row, Typography } from "antd";
import { MailOutlined } from "@ant-design/icons";

//http
import { requestPasswordReset } from "../../http/auth";

//images
import Logo from "../../images/logo.svg";

const ForgotPassword = () => {
  const [form] = Form.useForm();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailSentMessage, setEmailSentMessage] = useState(false);

  /**
   * 
   * @param {object} values 
   * @returns 
   */
  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      const res = await requestPasswordReset(values);

      if (res.data?.data.length > 0) {
        setEmailSentMessage(true);
        setEmail(values.username);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      return notification.error({
        message: "Error",
        description: `${error.response.data}`,
      });
    }
  };

  return (
    <>
      <Row
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          height: "100vh",
          backgroundColor: "#F5F8F9",
        }}
      >
        {emailSentMessage === true ? (
          <Col span={24} style={{ textAlign: "center" }}>
            <h2>Password Reset Link Sent!</h2>
            <p>
              Check your email <b>({email})</b> to find a password reset link
            </p>
            <Typography.Text>
              Goto{" "}
              <Link to="/reset-password">
                <Typography.Text className="back__to__2">
                  reset password
                </Typography.Text>
              </Link>{" "}
              screen
            </Typography.Text>
          </Col>
        ) : (
          <>
            <Col span={8}>
              <h2>Forgot Password</h2>
              <p>
                Enter the email associated with your account for a link to{" "}
                <br /> be sent to help you reset your password.
              </p>
              <Form form={form} onFinish={handleSubmit}>
                <div style={{ margin: "3.5em 0" }}>
                  <Form.Item name="username" hasFeedback="true">
                    <Input
                      prefix={
                        <>
                          <MailOutlined style={{ color: "#CBCECF" }} />
                        </>
                      }
                      type="email"
                      placeholder="Email Address"
                      className="input__group"
                      required
                    />
                  </Form.Item>
                </div>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="custom__primary__button"
                  size="large"
                  style={{ width: "100%" }}
                  loading={loading}
                >
                  Send Password Reset Link
                </Button>
                <p style={{ marginTop: "2em" }}>
                  <Typography.Text>Back to</Typography.Text>{" "}
                  <Link to="/login">
                    <Typography.Text
                      className="back__to"
                      style={{ fontWeight: "500" }}
                    >
                      Log in
                    </Typography.Text>{" "}
                  </Link>
                  screen
                </p>
              </Form>
            </Col>
            <div className="logo" style={{ left: "45%" }}>
              <p>
                Powered by <img src={Logo} alt="cmab" height="15px" />
              </p>
            </div>
          </>
        )}
      </Row>
    </>
  );
};

export default ForgotPassword;
