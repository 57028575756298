import { Button, Col, Modal, Progress, Row } from 'antd';
import React from 'react';

const ProgressBarModal = ({ isProgressBarVisible, setIsProgressBarVisible }) => {
    return (
        <Modal
        visible={isProgressBarVisible}
        title={<b>Updates</b>}
        onCancel={() => setIsProgressBarVisible(false)}
        footer={[
          <Button
            type="primary"
            className="custom__primary__button"
            onClick={() => setIsProgressBarVisible(false)}
          >
            Close
          </Button>,
        ]}>
            <Row>
                <Col span={24}>
                    <Progress percent={30} status="active"/>
                </Col>
            </Row>
        </Modal>
    );
};

export default ProgressBarModal;