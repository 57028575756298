import React, { useReducer, createContext } from 'react'
import jwtDecode from 'jwt-decode'

const initialState = {
  user: null,
  loading: false,
  isAuthenticated: false,
  accessToken: '',
  selectedTicket: null,
  step: 0,
  update: false,
}

const token = window.localStorage.getItem('accessToken')
const id = localStorage.getItem('id')

if (token && id) {
  const decodedToken = jwtDecode(token)
  const expiresAt = new Date(decodedToken.exp * 1000)

  if (new Date().getTime() > expiresAt) {
    window.localStorage.removeItem('accessToken')
    window.localStorage.removeItem('id')
  } else {
    initialState.isAuthenticated = true
    initialState.accessToken = token
  }
}

const AuthContext = createContext({
  user: null,
  isAuthenticated: false,
  loading: false,
  setUser: (userData) => {},
  login: (userData) => {},
  logout: () => {},
})

function authReducer(state, action) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        accessToken: action.payload.accessToken,
      }

    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      }

    case 'SET_TICKET':
      return {
        ...state,
        selectedTicket: action.payload,
      }

    case 'SET_STEP':
      return {
        ...state,
        step: action.payload,
      }

    case 'SET_UPDATE':
      return {
        ...state,
        update: action.payload,
      }

    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      }

    case 'LOGOUT':
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      }
    default:
      return state
  }
}



function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState)

  function login(userData) {
    window.localStorage.setItem('accessToken', userData.accessToken)
    window.localStorage.setItem('id', userData.id)

    dispatch({
      type: 'LOGIN',
      payload: userData,
    })
  }

  function setUser(userData) {
    dispatch({
      type: 'SET_USER',
      payload: userData,
    })
  }

  async function setSelectedTicket(ticket) {
    dispatch({
      type: 'SET_TICKET',
      payload: ticket,
    })
  }

  function setStep(number) {
    dispatch({
      type: 'SET_STEP',
      payload: number,
    })
  }

  function setUpdate(boolean) {
    dispatch({
      type: 'SET_UPDATE',
      payload: boolean,
    })
  }

  function setLoading(boolean) {
    dispatch({
      type: 'SET_LOADING',
      payload: boolean,
    })
  }

  function logout() {
    window.localStorage.removeItem('accessToken')
    window.localStorage.removeItem('id')
    dispatch({ type: 'LOGOUT' })
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        accessToken: state.accessToken,
        selectedTicket: state.selectedTicket,
        step: state.step,
        update: state.update,
        loading: state.loading,
        setLoading,
        setUpdate,
        setUser,
        login,
        logout,
        setSelectedTicket,
        setStep,
      }}
      {...props}
    />
  )
}

export { AuthContext, AuthProvider }
