import axios from 'axios'

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
}

export const loginUser = async (data) => {
  // console.log(data)
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/login`,
    data,
    config,
  )
  // console.log(res);
  return res
}

export const requestPasswordReset = async (data) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/password/request-reset`,
    data,
    config,
  )
  return res
}

export const passwordReset = async (data) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/password/reset`,
    data,
    config,
  )
  return res
}