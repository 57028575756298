import { Button, Col, Form, Input, Modal, notification, Row } from 'antd'
import React, { useState } from 'react'
import { resolveTicket } from '../../../http/ticket'

const ResolvedTicketModal = ({
  isResolvedModalVisible,
  setIsResolvedModalVisible,
  ticketId,
  accessToken,
  setSelectedTicket,
}) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values) => {
    setLoading(true)
    const data = {
      status: 'RESOLVED',
      reason: values.reason,

    }

    try {
      const res = await resolveTicket(ticketId, data, accessToken)
      if (res.data.data.length > 0) {
        setSelectedTicket(res.data.data[0])
        notification.success({
          message: 'Ticket Resolved',
          description: `You have resolved Ticket ID ${res.data.data[0].complaintNo}..`,
        })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)

      return notification.error({
        message: 'Error',
        description: `${error}`,
      })
    }

    setIsResolvedModalVisible(false)
  }

  return (
    <Modal
      visible={isResolvedModalVisible}
      title={<b>Resolution</b>}
      onCancel={() => setIsResolvedModalVisible(false)}
      footer={[
        <Button
          type="primary"
          key="reset"
          ghost="true"
          className="custom__ghost__button"
          onClick={() => {
            setIsResolvedModalVisible(false)
            form.resetFields()
          }}
        >
          Cancel
        </Button>,
        <Button
          type="primary"
          key="submit"
          htmlType="submit"
          className="custom__primary__button"
          onClick={form.submit}
          loading={loading}
        >
          Submit
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item label="Comments" name="reason">
              <Input.TextArea placeholder="Give comments..." rows={5} />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export default ResolvedTicketModal
