import { Layout, Steps } from "antd";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

//context
import { AuthContext } from "../../../context";

//custom css
import "../BaseLayout.css";

import { Capitalize } from "../../../utils/capitalize";

const TicketSideMenu = () => {
  const location = useLocation();
  const { user, selectedTicket, step } = useContext(AuthContext);

  return (
    <Layout.Sider className="ticket__side__menu" trigger={null} width={290}>
      <div className="ticket__details">
        <div>
          <p>
            Created by:{" "}
            {selectedTicket?.policyHolder
              ? selectedTicket?.policyHolder?.fullName
              : user?.fullName}
          </p>
          <p>
            Date:{" "}
            {selectedTicket?.createdAt
              ? new Date(selectedTicket?.createdAt).toDateString()
              : new Date().toDateString()}
          </p>
          <p>
            Status:{" "}
            {selectedTicket?.status
              ? Capitalize(selectedTicket?.status)
              : "Open"}
          </p>
        </div>
      </div>
      {location.pathname === "/tickets/new" ? (
        <div className="ticket__steps">
          <Steps direction="vertical" size="small" current={step}>
            <Steps.Step title="Create new ticket" />
            <Steps.Step title="Review and submit" />
          </Steps>
        </div>
      ) : (
        ""
      )}
    </Layout.Sider>
  );
};

export default TicketSideMenu;
