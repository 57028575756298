import React, { useEffect, useState } from 'react'
import { TwitterOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { Table, Tag, Typography } from 'antd'
import { Capitalize } from '../../utils/capitalize'

const { Text } = Typography
const ViewTicketsTable = ({
  user,
  tickets,
  loading,
  setTickets,
  tempTickets,
  filterForInsCol,
  filterForAssignee,
  setSelectedRowKeys,
  editOpen,
  setEditOpen,
  setSelectedTicketId
}) => {
  const [filterForComplaint, setFilterForComplaint] = useState([])
  const handleClick = (ticket) => {
    setEditOpen(!editOpen)
    setSelectedTicketId(ticket.id)
  }
  // console.log("tickets", tickets)
  let column = [
    {
      title: 'TicketID',
      dataIndex: 'complaintNo',
      key: 'complaintNo',
      align: 'center',
      ellipsis: true,
      sorter: (a, b) => a.complaintNo.length - b.complaintNo.length,
      render: (complaintNo, ticket) => (
          <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={ () => handleClick(ticket)}>
            {complaintNo}
          </span>
      ),
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      ellipsis: true,
      filters: [
        {
          text: 'Open',
          value: 'OPEN',
        },
        {
          text: 'Liability Determination',
          value: 'LIABILITY_DETERMINATION',
        },
        {
          text: 'In Review',
          value: 'IN_REVIEW',
        },
        {
          text: 'Pending Review',
          value: 'PENDING_REVIEW',
        },
        {
          text: 'Resolved',
          value: 'RESOLVED',
        },
      ],
      onFilter: (status, record) =>
        record?.status === status || record.status === undefined,
      render: (status) => (
        <Tag color={status === 'RESOLVED' ? 'success': status === 'SETTLED' ? 'success' : status === 'PENDING_REVIEW' ? 'error' : status === 'IN_REVIEW' ? 'processing' : status === 'LIABILITY_DETERMINATION' ? 'warning' : null }> 
            <Text
              type={status === 'RESOLVED' ? 'success': status === 'SETTLED' ? 'success' : status === 'PENDING_REVIEW' ? 'error' : status === 'IN_REVIEW' ? 'processing' : status === 'LIABILITY_DETERMINATION' ? 'warning' : null }
              style={  status === "LIABILITY_DETERMINATION" ? { width: 80} : undefined}
              ellipsis={ status === "LIABILITY_DETERMINATION" ? { tooltip: status } : false }
            >
              {status ? Capitalize(status) : 'Open'}
            </Text> 
        </Tag>
      ),
    },
    {
      title: 'Date/Time',
      dataIndex: 'dateOfOccurrence',
      key: 'dateOfOccurrence',
      align: 'center',
      ellipsis: true,
      render: (dateOfOccurrence) => (
        <>
          {dateOfOccurrence ? new Date(dateOfOccurrence).toDateString() : 'N/A'}
        </>
      ),
    },
    {
      title: 'Complainant Name',
      dataIndex: 'complaintName',
      key: 'complaintName',
      align: 'center',
      ellipsis: true,
      filters: filterForComplaint,
      filterSearch: true,
      onFilter: (value, record) =>
        record?.complaintName?.indexOf(value) === 0 ||
        record?.companyName?.indexOf(value) === 0,
      render: (complaintName, ticket) => (
        <>
          {ticket?.type === 'INDIVIDUAL'
            ? complaintName
            : ticket?.type === 'CORPORATE'
            ? ticket?.companyName
            : 'N/A'}
        </>
      ),
    },
    {
      title: 'Ticket Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      ellipsis: true,
      filters: [
        {
          text: 'Individual',
          value: 'INDIVIDUAL',
        },
        {
          text: 'Corporate',
          value: 'CORPORATE',
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,
      render: (type) => (
        <span>
          {type === 'INDIVIDUAL'
            ? 'Individual'
            : type === 'CORPORATE'
            ? 'Corporate'
            : 'N/A'}
        </span>
      ),
    },
    {
      title: 'Channel',
      dataIndex: 'channel',
      key: 'channel',
      align: 'center',
      ellipsis: true,
      filters: [
        {
          text: 'WhatsApp',
          value: 'WHATS_APP',
        },
        {
          text: 'Online Form',
          value: 'ONLINE_FORM',
        },
        {
          text: 'Twitter',
          value: 'TWITTER',
        },
        {
          text: 'Dashboard',
          value: 'DASHBOARD',
        },
        {
          text: 'Ussd',
          value: 'USSD',
        },
      ],
      onFilter: (value, record) => record.channel.indexOf(value) === 0,
      filterSearch: true,
      render: (channel) => (
        <span>
          {channel !== 'WHATS_APP' || channel !== 'TWITTER' ? (
            Capitalize(channel)
          ) : channel === 'WHATS_APP' ? (
            <span>
              <WhatsAppOutlined /> WhatsApp
            </span>
          ) : channel === 'TWITTER' ? (
            <span>
              <TwitterOutlined /> Twitter
            </span>
          ) : (
            'N/A'
          )}
        </span>
      ),
    },
    {
      title: 'Insurer Name',
      dataIndex: 'insurer',
      key: 'insurer',
      align: 'center',
      ellipsis: true,
      filters: filterForInsCol,
      filterSearch: true,
      onFilter: (value, record) => record?.insurer === value,
    },
    {
      title: 'Assignee name',
      dataIndex: 'assignedTo',
      key: 'assignee',
      ellipsis: true,
      align: 'center',
      filters: filterForAssignee,
      filterSearch: true,
      onFilter: (value, record) => record?.assignedTo?.fullName === value,
      render: (assignedTo) => (
        <Tag color={assignedTo ? 'success': 'warning' }> 
         <span>{assignedTo ? assignedTo?.fullName : 'Unassigned'}</span>

        </Tag>
      ),
    },
    {
      title: 'Escalated',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      align: 'center',
      filters: [
        {
          text: 'Escalated',
          value: true,
        },
        {
          text: 'Not Escalated',
          value: false,
        },
      ],
      onFilter: (value, record) =>
        value ? record?.escalations.length > 0 : record.escalations.length < 1,
      render: (_, ticket) => (
        <span>{ticket?.escalations.length > 0 ? 'Yes' : 'No'}</span>
      ),
    },
    {
      title: 'Insurer Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      ellipsis: true,
      filters: [
        {
          text: 'Life',
          value: 'LIFE',
        },
        {
          text: 'Non-Life',
          value: 'NON_LIFE',
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,
      render: (type) => (
        <span>
          {type === 'LIFE'
            ? 'Life'
            : type === 'NON-LIFE'
            ? 'Non-Life'
            : 'N/A'}
        </span>
      ),
    },

  ]
  if (user?.type !== 'CMAB') {
    column = column.filter((header) => header.dataIndex !== 'insurer')
  }

  const rowSelection = {
    onChange: (selectedRowKeys, _) => {
      setSelectedRowKeys(selectedRowKeys)
    },
  }

  useEffect(() => {

    if (tickets?.length > 0) {
      // let _data = [{text: 'Company', children: [] }, {text: 'Complainant', children: [] }]
      let _filters = tickets.map((ticket) => {
        if (ticket?.type === 'CORPORATE') {
          return {
            text: ticket.companyName,
            value: ticket.companyName,
          }
        } else {
          return {
            text: ticket.complaintName,
            value: ticket.complaintName,
          }
        }
      })
      _filters = [
        ..._filters
          .reduce((map, obj) => map.set(obj.text, obj), new Map())
          .values(),
      ]
      setFilterForComplaint(_filters)
    }
  }, [tickets])
  return (
    <>
      <Table
        rowSelection={{
          ...rowSelection,
        }}
        pagination={{ pageSize: 8 }}
        columns={column}
        dataSource={tickets}
        loading={loading}
        onChange={(pagination, filters, sorter, data) => {
          let _myObject = filters
          user?.company.type !== 'CMAB' ?? delete _myObject.insurer

          if (Object.values(_myObject).every((i) => i === null)) {
            setTickets(tempTickets)
          } else {
            // console.log('first')
            setTickets(data.currentDataSource)
          }
        }}
      />
    </>
  )
}

export default ViewTicketsTable
