/* eslint-disable react-hooks/exhaustive-deps */
import io from 'socket.io-client'
import { utils, writeFile } from 'xlsx'
import { useHistory } from 'react-router-dom'
import { DeleteFilled } from '@ant-design/icons'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, notification, PageHeader, Popconfirm, Row } from 'antd'

//components imports
import ProgressBarModal from '../../components/Modals/ProgressBarModal'
import ViewTicketsTable from '../../components/Tables/ViewTicketsTable'

//utils & context
import { AuthContext } from '../../context'
import { Capitalize } from '../../utils/capitalize'

//http
import {
  deleteTicket,
  getTickets,
  getTicketsPerInsurer,
  getTicketsPerQuery,
} from '../../http/ticket'
// import { getPolicyHolders } from '../../http/policyholder'

//custom css
import './Ticket.css'

//enum
import { CompanyTypes, Roles } from '../../models/enum'
import { getInsurers } from '../../http/insurer'
import { getAllStaffs, getStaffs } from '../../http/staff'
import ExportModal from '../../components/Modals/ExportModal'
import EditTicketModal from '../../components/Modals/Tickets/EditTicketModal'
// import { getPolicyHolders } from '../../http/policyholder'

//initializing the socket
const socket = io.connect(`${process.env.REACT_APP_BASE_API_URL}`)

const Index = () => {
  const { accessToken, setSelectedTicket, user } = useContext(AuthContext)

  const history = useHistory()

  const [tickets, setTickets] = useState([])
  const [loading, setLoading] = useState(false)
  // const [deleted, setDeleted] = useState(false)
  const [tempTickets, setTempTickets] = useState([])
  const [selectedTab, setSelectedTab] = useState('All')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [filterForInsCol, setFilterForInsCol] = useState([])
  // const [filterForPH, setFilterForPH] = useState([])
  const [filterForAssignee, setFilterForAssignee] = useState([])
  const [isProgressBarVisible, setIsProgressBarVisible] = useState(false)
  const [isExportModalVisible, setIsExportModalVisible] = useState(false)
  const [fromValue, setFromValue] = useState(null)
  const [editOpen, setEditOpen] = useState(false)
  const [toValue, setToValue] = useState(null)
  const [selectedTicketId, setSelectedTicketId] = useState("")
  // console.log(`fromValue ${fromValue} toValue${toValue}` )

  // const slicedTicket = () => console.log("sliced ticket", tickets.slice(fromValue, toValue))
  const tabs = ['All', 'My Tickets', 'Escalated']

  /**
   * deleting ticket(s)
   *
   * @returns { null | import('react').ReactNode}
   */
  const handleDelete = async () => {
    let res = []
    let _ids = []
    setLoading(true)

    try {
      for (const id in selectedRowKeys) {
        if (Object.hasOwnProperty.call(selectedRowKeys, id)) {
          const _id = selectedRowKeys[id]
          res = await deleteTicket(_id, accessToken)
        }

        _ids.push(res.data?.data[0])
      }

      if (res.data?.data.length > 0) {
        notification.success({
          message: 'Ticket Deleted',
          description: `${_ids.length} ticket(s) deleted successfully`,
        })
      }
      setSelectedRowKeys([])
    } catch (error) {
      return notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
      })
    } finally {
      setLoading(false)
    }
  }

/**
   * exporting selected tickets
   */
  const handleExport = async () => {
    if(fromValue === null || toValue === null){
      setIsExportModalVisible(true)
      notification.error({
        message: "Error",
        description: "From and To values cannot be null"
      })
    }else{
      const ticketsToExport = tickets.slice(fromValue, toValue)
      const _tickets = ticketsToExport.map((ticket) => {
      return {
        'Ticket ID': ticket?.complaintNo,
        Title: Capitalize(ticket?.title),
        'Date Of Occurrence': new Date(ticket?.dateOfOccurrence).toDateString(),
        'Complaint Name': ticket?.complaintName,
        'Ticket Type': Capitalize(ticket?.type),
        Channel: Capitalize(ticket?.channel),
        'Insurer Name': ticket?.insurer,
        Assignee: ticket?.assignedTo?.fullName ?? 'Unassigned',
        Status: ticket?.status ? Capitalize(ticket?.status) : 'Open',
      }
      })
      
      let ticketSheet = utils.json_to_sheet(_tickets, {
        dateNF: 'dd/mm/yy',
      })
      let ticketBook = utils.book_new()
      
      utils.book_append_sheet(ticketBook, ticketSheet, 'tickets')
      writeFile(ticketBook, 'tickets.xls', {
        cellDates: 'd',
      })
      setIsExportModalVisible(false)
    }
  }
  const handleExportAll = async () => {
    const _tickets = tickets.map((ticket) => {
      return {
        'Ticket ID': ticket?.complaintNo,
        Title: Capitalize(ticket?.title),
        'Date Of Occurrence': new Date(ticket?.dateOfOccurrence).toDateString(),
        'Complaint Name': ticket?.complaintName,
        'Ticket Type': Capitalize(ticket?.type),
        Channel: Capitalize(ticket?.channel),
        'Insurer Name': ticket?.insurer,
        Assignee: ticket?.assignedTo?.fullName ?? 'Unassigned',
        Status: ticket?.status ? Capitalize(ticket?.status) : 'Open',
      }
    })

    let ticketSheet = utils.json_to_sheet(_tickets, {
      dateNF: 'dd/mm/yy',
    })
    let ticketBook = utils.book_new()

    utils.book_append_sheet(ticketBook, ticketSheet, 'tickets')
    writeFile(ticketBook, 'tickets.xls', {
      cellDates: 'd',
    })
    setIsExportModalVisible(false)
  }
  /**
   * handle changes when tab is selected
   *
   * @param {string} tab
   */
  const handleChange = async (tab) => {
    setSelectedTab(tab)

    if (tab === 'Escalated') {
      const _tickets = tempTickets.filter((i) => i.escalations.length > 0)
      setTickets(_tickets)
    }

    if (tab === 'My Tickets') {
      const _tickets = tempTickets.filter(
        (i) => i.assignedTo?.id === (user?.id || user?.sub),
      )
      setTickets(_tickets)
    }

    if (tab === 'All') {
      setTickets(tempTickets)
    }
  }

  /**
   * getting data for the page.
   *
   * @returns {object | ReactNode}
   */
  const getData = async () => {
    setLoading(true)
    let res = []
    let query = {}

    try {
      switch (user?.company?.type) {
        case CompanyTypes.insurer:
          res = await getTicketsPerInsurer(accessToken, user?.company.id)
          break

        case CompanyTypes.cmab:
          res = await getTickets(accessToken)
          break

        case CompanyTypes.gia:
          query = {
            tier: 'TIER_2',
            insurer: user?.company?.id,
          }
          res = await getTicketsPerQuery(accessToken, query)
          break

        case CompanyTypes.nic:
          query = {
            tier: 'TIER_3',
          }
          res = await getTicketsPerQuery(accessToken, query)
          break
        default:
          break
      }

      let displayTickets = res?.data?.data.map((ticket) => {
        return {
          ...ticket,
          complaintName: ticket?.policyHolder?.fullName,
          insurer: ticket?.insurer?.name,
          key: ticket?.id,
        }
      })

      setTickets(displayTickets)
      setTempTickets(displayTickets)
      // setDeleted(false)
    } catch (error) {
      return notification.error({
        message: 'Error',
        description: `${error}`,
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [user])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      try {
        const res = await getInsurers(accessToken)
        if (res.data?.data.length > 0) {
          const _data = res.data.data.map((insurer) => {
            return {
              text: insurer.name,
              value: insurer.name,
            }
          })
          setFilterForInsCol(_data)
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: `${error.response.data.message}`,
        })
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [user])

  // useEffect(() => {
  //   const getData = async () => {
  //     setLoading(true)
  //     try {
  //       const res = await getPolicyHolders(accessToken)
  //       console.log(res)
  //       // if (res.data?.data.length > 0) {
  //       //   const _data = res.data.data.map((insurer) => {
  //       //     return {
  //       //       text: insurer.name,
  //       //       value: insurer.name,
  //       //     }
  //       //   })
  //       //   setFilterForPH(_data)
  //       // }
  //     } catch (error) {
  //       notification.error({
  //         message: 'Error',
  //         description: `${error.response.data.message}`,
  //       })
  //     } finally {
  //       setLoading(false)
  //     }
  //   }
  //   getData()
  // }, [user])

  // useEffect(() => {
  //   const getData = async () => {
  //     setLoading(true)
  //     try {
  //       const res = await getPolicyHolders(accessToken)
  //       console.log(res)
  //       // if (res.data?.data.length > 0) {
  //       //   const _data = res.data.data.map((insurer) => {
  //       //     return {
  //       //       text: insurer.name,
  //       //       value: insurer.name,
  //       //     }
  //       //   })
  //       //   setFilterForPH(_data)
  //       // }
  //     } catch (error) {
  //       notification.error({
  //         message: 'Error',
  //         description: `${error.response.data.message}`,
  //       })
  //     } finally {
  //       setLoading(false)
  //     }
  //   }
  //   getData()
  // }, [user])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      let res = []
      try {
        if (user?.company?.type === CompanyTypes.cmab) {
          res = await getAllStaffs(accessToken)
        } else {
          res = await getStaffs(accessToken)
        }

        if (res.data?.data.length > 0) {
          let displayStaffs = [...res.data.data].map((staff) => {
            return {
              text: staff?.fullName,
              value: staff?.fullName,
            }
          })

          setFilterForAssignee(displayStaffs)
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: `${error.response.data.message}`,
        })
      } finally {
        setLoading(false)
      }
    }

    getData()
  }, [user])

  useEffect(() => {
    socket.on('new_complaint', (data) => {
      setTickets((ticket) => [data, ...ticket])
      setTempTickets((ticket) => [data, ...ticket])
    })

    socket.on('delete_complaint', (data) => {
      const _tickets = tickets?.filter((ticket) => ticket.id !== data.id)

      setTickets(_tickets)
      setTempTickets(_tickets)
    })

    socket.on('new_escalation', (data) => {
      // console.log('escalate', data)
      setTickets((ticket) => [data, ...ticket])
      setTempTickets((ticket) => [data, ...ticket])
    })
  }, [socket])

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Tickets"
        subTitle={
          <>
            <div className="tabs">
              {tabs.map((tab) => (
                <p
                  className={`user__type__tab ${
                    tab === selectedTab ? 'userTab__active' : ''
                  } `}
                  onClick={() => handleChange(tab)}
                >
                  {tab}
                </p>
              ))}
            </div>
          </>
        }
        extra={
          <>
            {user?.role === Roles.admin && selectedRowKeys.length > 0 && (
              <>
                <Popconfirm
                  title="Are you sure want to delete the selected tickets(s)?"
                  cancelText="No"
                  okText="Yes"
                  onConfirm={() => handleDelete()}
                >
                  <Button type="danger" ghost="true" loading={loading}>
                    <DeleteFilled />
                    Delete
                  </Button>
                </Popconfirm>
              </>
            )}
            {user?.role === Roles.admin && (
              <>
                <Button
                  type="primary"
                  ghost="true"
                  className="custom__ghost__button"
                  onClick={() => setIsExportModalVisible(true)}
                >
                  Export
                </Button>
              </>
            )}

            <Button
              type="primary"
              className="custom__primary__button"
              onClick={() => history.push('/tickets/new')}
            >
              Create Ticket
            </Button>
          </>
        }
      />
      <Row>
        <Col>
          <ViewTicketsTable
            user={user}
            tickets={tickets}
            loading={loading}
            setTickets={setTickets}
            tempTickets={tempTickets}
            filterForInsCol={filterForInsCol}
            filterForAssignee={filterForAssignee}
            setSelectedTicket={setSelectedTicket}
            setSelectedRowKeys={setSelectedRowKeys}
            editOpen={editOpen}
            setEditOpen={setEditOpen}
            setSelectedTicketId={setSelectedTicketId}
          />
        </Col>
      </Row>
      {isExportModalVisible && (
        <ExportModal
          isExportModalVisible={isExportModalVisible}
          setIsExportModalVisible={setIsExportModalVisible}
          setFromValue={setFromValue}
          setToValue={setToValue}
          handleExport={handleExport}
          handleExportAll={handleExportAll}
        />
      )}
      <ProgressBarModal
        isProgressBarVisible={isProgressBarVisible}
        setIsProgressBarVisible={setIsProgressBarVisible}
      />
      <EditTicketModal
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        selectedTicketId={selectedTicketId}
      />
    </>
  )
}

export default Index
