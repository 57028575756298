/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, notification, PageHeader, Row, Select } from "antd";

//components
import ViewStaffsTable from "../../components/Tables/ViewStaffsTable";
import NewStaffModal from "../../components/Modals/Staffs/NewStaffModal";
import UpdateStaffModal from "../../components/Modals/Staffs/UpdateStaffModal";

//context
import { AuthContext } from "../../context";

//http
import { deleteStaff } from "../../http/staff";
import { getInsurers } from "../../http/insurer";

//enum
import { Roles } from "../../models/enum";

const Index = () => {
  const { accessToken, user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);

  const [staffs, setStaffs] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState({});
  const [companies, setCompanies] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const roles = [
    { text: "Admin", value: "ADMIN" },
    { text: "Complaint Handler", value: "COMPLAINT_HANDLER" },
  ];
  const levels = ["TIER_1", "TIER_2", "TIER_3"];


  /**
   * function for deleting staff(s)
   * 
   * @param {string} value 
   * @returns 
   */
  const handleChange = async (value) => {
    let res = {};

    if (value === "Delete") {
      setLoading(true);

      try {
        for (const id in selectedRowKeys) {
          if (Object.hasOwnProperty.call(selectedRowKeys, id)) {
            const _id = selectedRowKeys[id];
            res = await deleteStaff(_id, accessToken);
          }
        }

        if (res.data?.data.length > 0) {
          notification.success({
            message: "Staff Deleted",
            description: `${selectedRowKeys.length} staff(s) deleted successfully.`,
          });
        }
        setLoading(false);
        setDeleted(true);
      } catch (error) {
        setLoading(false);
        return notification.error({
          message: "Error",
          description: `${error.response.data.message}`,
        });
      }
    } else {
      setIsUpdateModalVisible(true);
    }

    setSelectedRowKeys([]);
  };

  useEffect(() => {
    /**
     * function for getting all companies
     * 
     */
    const getCompanies = async () => {
      let _companies = [];

      try {
        const res = await getInsurers(accessToken);
        if (res?.data?.data.length > 0) {
          if (user?.company.type !== "CMAB") {
            _companies = res.data.data.filter((i) => i.type !== "CMAB");
            setCompanies(_companies);
          } else {
            setCompanies(res.data.data);
          }
        }
      } catch (error) {
        throw new Error(error);
      }
    };

    getCompanies();
  }, []);

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Staffs"
        subTitle={
          <>
            {user?.role === Roles.admin && selectedRowKeys?.length > 0 && (
              <Select placeholder="--- Apply ---" onChange={handleChange}>
                {selectedRowKeys.length === 1 ? (
                  <>
                    <Select.Option value="Edit">Edit</Select.Option>
                    <Select.Option value="Delete">Delete</Select.Option>
                  </>
                ) : (
                  <Select.Option value="Delete">Delete</Select.Option>
                )}
              </Select>
            )}
          </>
        }
        extra={
          <>
            {/* {user?.role === "MANAGER" && ( */}
            {user?.role === Roles.admin && (
              <Button
                type="primary"
                className="custom__primary__button"
                onClick={() => setIsModalVisible(true)}
              >
                Create Staff
              </Button>
            )}
          </>
        }
      />
      <Row>
        <Col>
          <ViewStaffsTable
            staffs={staffs}
            setStaffs={setStaffs}
            accessToken={accessToken}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            setSelectedStaff={setSelectedStaff}
            loading={loading}
            setLoading={setLoading}
            user={user}
            setDeleted={setDeleted}
            setUpdated={setUpdated}
            deleted={deleted}
            updated={updated}
          />
        </Col>
      </Row>
      {isModalVisible && (
        <NewStaffModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          setStaffs={setStaffs}
          companies={companies}
          accessToken={accessToken}
          user={user}
          roles={roles}
          levels={levels}
        />
      )}
      {isUpdateModalVisible && (
        <UpdateStaffModal
          accessToken={accessToken}
          isUpdateModalVisible={isUpdateModalVisible}
          setIsUpdateModalVisible={setIsUpdateModalVisible}
          selectedStaff={selectedStaff}
          companies={companies}
          roles={roles}
          levels={levels}
          user={user}
          setUpdated={setUpdated}
        />
      )}
    </>
  );
};

export default Index;
