import { notification } from "antd";
import { uploadImage } from "../http/image";

export const getFile = async (imageUrl, setImageLoading, accessToken) => {
    setImageLoading(true);
    const form = new FormData();
    form.append("file", imageUrl);

    try {
      const res = await uploadImage(form, accessToken);
      if (res.data.data.length > 0) 
        return {
          filename: res.data.data[0].originalname,
          linkUrl: res.data.data[0].linkUrl,
          size: parseInt(res.data.data[0].size)
        };
    } catch (error) {
      notification.error({
        message: "Error",
        description: `${error.response.data.message}`,
      });
    } finally {
      setImageLoading(false);
    }
  };
