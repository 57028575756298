import axios from 'axios'

export const uploadImage = async (fileData, token) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  }
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/storage`,
    fileData,
    config,
  )
  return res
}

export const getDashboardContents = async (token, startDate , endDate) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  }
  const enddate = endDate ?? new Date()
  const startdate = startDate ?? new Date('2022-01-01')
 

  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/dashboard?endDate=${enddate.toISOString()}&startDate=${startdate.toISOString()}`,
    config,
  )
    // console.log(res)
  return res
}

export const getDashboardContentsPerInsurer = async (token, id, startDate , endDate) => {
  const enddate = endDate ?? new Date()
  const startdate = startDate ?? new Date('2022-01-01')
 
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/dashboard?insurer=${id}&endDate=${enddate.toISOString()}&startDate=${startdate.toISOString()}`,
    config,
  )

  return res
}

export const getDashboardContentsPerQuery = async (token, query, startDate , endDate) => {
  const enddate = endDate ?? new Date()
  const startdate = startDate ?? new Date('2022-01-01')
 
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/dashboard?tier=${query.tier}&endDate=${enddate.toISOString()}&startDate=${startdate.toISOString()}`, config,
  )

  return res
}

export const getNotifications = async (token) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/notifications`,
    config,
  )

  return res
}

export const deleteNotification = async (id, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.delete(
    `${process.env.REACT_APP_API_URL}/notifications/${id}`,
    config,
  )

  return res
}
