export const Roles = {
  admin: "ADMIN",
  complaintHandler: "COMPLAINT_HANDLER",
  complainant: "COMPLAINT",
};

export const CompanyTypes = {
  insurer: "INSURER",
  cmab: "CMAB",
  gia: "GIA",
  nic: "NIC",
};
