/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Typography, Card, DatePicker , notification, Button } from 'antd'
import React, { useEffect, useState, useContext } from 'react'
import { CompanyTypes } from '../../models/enum'
import { getDashboardContentsPerInsurer } from '../../http/image'
import { getDashboardContentsPerQuery } from '../../http/image'
import { getDashboardContents } from "../../http/image";
//context
import { AuthContext } from "../../context"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts'

//custom css
import './Overview.css'

const ChannelCard = ({ tickets, loading, setLoading }) => {
  const [ticketData, setTicketData] = useState([])
  // const [temp, setTemp] = useState([])
  const {user, accessToken } = useContext(AuthContext)

  const colors = [
    '#8BFCCB54',
    '#1C458454',
    '#B8FC8B54',
    '#FC8B8B54',
    '#FC8BED54',
    '#8BF4FC54',
  ]
  // const channels = [
  //   { name: 'WhatsApp', value: 'WHATS_APP' },
  //   { name: 'Online Form', value: 'ONLINE_FORM' },
  //   { name: 'Twitter', value: 'TWITTER' },
  //   { name: 'USSD', value: 'USSD' },
  //   { name: 'Dashboard', value: 'DASHBOARD' },
  // ]

  const handleReset = async () => {
    let res = []
    let query = {}
    try {
      setLoading(true)
      switch (user?.company?.type) {
        case CompanyTypes.insurer:
          res = await getDashboardContentsPerInsurer(
            accessToken,
            user?.company.id,
          )
          break

        case CompanyTypes.cmab:
          res = await getDashboardContents(accessToken)
          break

        case CompanyTypes.gia:
          query = {
            tier: 'TIER_2',
            // insurer: user?.company?.id,
          }

          res = await getDashboardContentsPerQuery(accessToken, query)
          // res = await getDashboardContentsPerInsurer(accessToken, user?.company?.id)
          break

        case CompanyTypes.nic:
          query = {
            tier: 'TIER_3',
          }
          // res = await getDashboardContentsPerInsurer(accessToken, user?.company?.id)
          res = await getDashboardContentsPerQuery(accessToken, query)
          break
        default:
          break
      }
      // const res =  await getDashboardContents(accessToken)
      if (res.data?.statusCode === 200) {
        // console.log(res.data?.data);
        setTicketData(res.data?.data)
      }
    } catch (error) {
      return notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
      })
    } finally {
      setLoading(false)
    }
  }

  //fires when the dates are changed
  const handleDate = async (value) => {
   
    let res = []
    let query = {}
      if (value?.length === undefined) {
        setTicketData(tickets)
        return
      }
      //Value here is startDate and endDate based on range
      try {
        setLoading(true)
        switch (user?.company?.type) {
          case CompanyTypes.insurer:
            res = await getDashboardContentsPerInsurer(
              accessToken,
              user?.company.id,
              value[0], value[1]
            )
            break
  
          case CompanyTypes.cmab:
            res = await getDashboardContents(accessToken, value[0], value[1])
            break
  
          case CompanyTypes.gia:
            query = {
              tier: 'TIER_2',
              // insurer: user?.company?.id,
            }
  
            res = await getDashboardContentsPerQuery(accessToken, query, value[0], value[1])
            // res = await getDashboardContentsPerInsurer(accessToken, user?.company?.id)
            break
  
          case CompanyTypes.nic:
            query = {
              tier: 'TIER_3',
            }
            // res = await getDashboardContentsPerInsurer(accessToken, user?.company?.id)
            res = await getDashboardContentsPerQuery(accessToken, query, value[0], value[1])
            break
          default:
            break
        }
        if (res.data?.statusCode === 200) {
          // console.log(res.data?.data);
          setTicketData(res.data?.data)
        }
      } catch (error) {
      return notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    // let _data = []
    setLoading(true)
    setTicketData(tickets)
      // setTemp(tickets)
      setLoading(false)
    // if (tickets.length > 0) {
    //   _data = channels.map((channel) => {
    //     let count = 0
    //     tickets.forEach((ticket) => {
    //       if (ticket.channel === channel.value) {
    //         count = count + 1
    //       }
    //     })

    //     return {
    //       name: channel.name,
    //       channel: count,
    //     }
    //   })

      
    // }
  }, [tickets])

  return (
    <>
      <Card className="channel__card" loading={loading}>
        <Row justify="space-between">
          <Col>
            <Typography.Title level={5} className="section__title">
              Channel Summary
            </Typography.Title>
          </Col>

          <Row > 
          <Col className="ticket__space">
            <DatePicker.RangePicker onChange={handleDate}/>
          </Col>
          <Col> 
          
          <Button
                    type="primary"
                    className="custom__primary__button"
                    onClick={handleReset}
                >
                    Reset
                </Button>
                 </Col>
          </Row>
        </Row>
        <Row>
          <Col span={24} style={{ top: '25px' }}>
            <ResponsiveContainer minWidth={100} minHeight={240}>
              <BarChart
                width={950}
                height={300}
                data={ticketData[0]?.channel}
                barCategoryGap={0}
                margin={{
                  top: 5,
                }}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="channel">
                  {ticketData[0]?.channel?.map((_, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default ChannelCard
