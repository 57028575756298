import {
  Modal,
  Button,
  Col,
  Row,
  Form,
  Input,
  Select,
  Upload,
  notification,
  Spin,
  Popconfirm
} from "antd";
import React, { useEffect, useState } from "react";
import { FileImageOutlined } from "@ant-design/icons";
import { activateInsurer, deactivateInsurer } from "../../../http/insurer";
//http
import { updateInsurer } from "../../../http/insurer";
import { uploadImage } from "../../../http/image";

const UpdateInsurerModal = ({
  updateModalVisible,
  setUpdateModalVisible,
  accessToken,
  selectedInsurer,
  setUpdated,
}) => {
  const [form] = Form.useForm();
  const [imageLoading, setImageLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedType, setSelectedType] = useState(selectedInsurer?.type);
  const [imageUrl, setImageUrl] = useState(null);
  const [base64, setBase64] = useState(selectedInsurer?.linkUrl);

  // console.log("selected", selectedInsurer)
  const handleRow = async (selectedInsurer, action) => {
    if(action === "enable"){
      try{
        await activateInsurer(selectedInsurer.id, accessToken)
        setUpdated(true)
        window.location.reload()
      }catch{}
    }else if(action === "disable"){
      try{
        await deactivateInsurer(selectedInsurer.id, accessToken)
        setUpdated(true)
        window.location.reload()
      }catch{}
    }
  }

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };
  
  const statuses = [
    {
      text: "Good Standing",
      value: "GOOD_STANDING",
    },
    {
      text: "Administration",
      value: "ADMINISTRATION",
    },
  ];
  const insurerTypes = ["LIFE", "NONLIFE"];
  const types = ["INSURER", "GIA", "NIC"];

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 80 }} value="+233">
        <Select.Option value="+233">+233</Select.Option>
      </Select>
    </Form.Item>
  );

  const getType = (value) => {
    setSelectedType(value);
  };

  const handleImage = (info) => {
    setImageUrl(info.file?.originFileObj);

    getBase64(info.file?.originFileObj, (imageUrl) => setBase64(imageUrl));
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const getFile = async (imageUrl) => {
    setImageLoading(true);

    const form = new FormData();
    form.append("file", imageUrl);

    try {
      const res = await uploadImage(form, accessToken);
      if (res.data?.data.length > 0) return res.data.data[0];
    } catch (error) {
      notification.error({
        message: "Error",
        description: `${error.response.data.message}`,
      });
    } finally {
      setImageLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    let fileData = {};

    if (imageUrl !== null) {
      let file = await getFile(imageUrl);

      fileData = {
        filename: file.filename,
        linkUrl: file.linkUrl,
        size: parseInt(file.size),
      };
    } else {
      fileData = {
        filename: selectedInsurer?.logo?.filename,
        linkUrl: selectedInsurer?.logo?.linkUrl,
        size: selectedInsurer?.logo.size,
      };
    }

    const data = {
      name: values.name,
      email: values.email,
      phoneNumber:
        values.phoneNumber.length > 10
          ? values.phoneNumber
          : "+233" + values.phoneNumber,
      password: "",
      companyStatus: values.status,
      type: values.type,
      logo: fileData,
      insurerType:
        values.type || selectedType === "INSURER" ? values.insurerType : null,
    };
    setLoading(true);
    try {
      const res = await updateInsurer(selectedInsurer?.id, data, accessToken);
      if (res.data.data.length > 0) {
        notification.success({
          message: "Insurer edited",
          description: `${res?.data.data[0].name} has been edited successfully.`,
        });
      }
      setLoading(false);
      setBase64("");
      setUpdated(true);
    } catch (error) {
      setLoading(false);
      return notification.error({
        message: "Error",
        description: `${error.response.data.message}`,
      });
    }

    setUpdateModalVisible(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      prefix: "+233",
      name: selectedInsurer?.name,
      email: selectedInsurer?.email,
      phoneNumber: selectedInsurer?.phoneNumber,
      status: selectedInsurer?.companyStatus,
      type: selectedInsurer?.type,
      insurerType: selectedInsurer?.insurerType
        ? selectedInsurer?.insurerType
        : null,
    });

    setBase64(selectedInsurer?.logo?.linkUrl);
  }, [form, selectedInsurer]);

  return (
    <>
      <Modal
        title={[
          <>  
          <Row justify="space-between">
          <h4 style={{color: "#000"}}>Update Company</h4>

{selectedInsurer.isActive === true ? 
    <Popconfirm placement="top" title="Are you sure you want disable this insurer" onConfirm={() => handleRow(selectedInsurer, "disable")} okText="Confirm" cancelText="Cancel" className="space-left">
      <Button className="disable-btn">Disable</Button> 
    </Popconfirm>
    : <Popconfirm placement="top" title="Are you sure you want enable this insurer" onConfirm={() => handleRow(selectedInsurer, "enable")} okText="Confirm" cancelText="cancel" className="space-left">
        <Button className="enable-btn">Enable</Button>
      </Popconfirm>
  }
          </Row>
         
          </>
        ]}
        visible={updateModalVisible}
        confirmLoading={loading}
        width={450}
        onCancel={() => {
          setUpdateModalVisible(false);
        }}
        footer={[
          <>
            {/* {selectedInsurer.isActive === true ? 
              <Popconfirm placement="top" title="Are you sure you want disable this insurer" onConfirm={() => handleRow(selectedInsurer, "disable")} okText="Confirm" cancelText="Cancel">
                <Button className="disable-btn">Disable</Button> 
              </Popconfirm>
              : <Popconfirm placement="top" title="Are you sure you want enable this insurer" onConfirm={() => handleRow(selectedInsurer, "enable")} okText="Confirm" cancelText="cancel">
                  <Button className="enable-btn">Enable</Button>
                </Popconfirm>
            } */}
            <Button
              type="primary"
              key="reset"
              ghost="true"
              className="custom__ghost__button"
              onClick={() => {
                setUpdateModalVisible(false);
                form.resetFields();
              }}
            >
              Cancel
            </Button>,
            <Button
              type="primary"
              key="submit"
              htmlType="submit"
              className="custom__primary__button"
              onClick={form.submit}
              loading={loading}
              >
              Update Company
            </Button>,
          </>
        ]}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          scrollToFirstError
        >
          <Row gutter="24">
            <Col span={12}>
              <Spin spinning={imageLoading}>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  onChange={handleImage}
                >
                  {base64 ? (
                    <img src={base64} alt="avatar" style={{ width: "100%" }} />
                  ) : (
                    <FileImageOutlined
                      style={{ color: "#1c4584", fontSize: "30px" }}
                    />
                  )}
                </Upload>
              </Spin>
            </Col>
            <Col span={12}>
              <p>
                *Should not be more than 3MB <br />
                *Should be in png/jpeg/jpg/svg/webp format <br />
              </p>
            </Col>
            <Col span={24}>
              <Form.Item name="name" label="Company Name"
                 rules={[
                  {
                    required: true,
                    message: 'Please input your Company Name!',
                  },
                ]}
              >
                <Input/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="email" label="Primary Email Address"
                 rules={[
                  {
                    required: true,
                    message: 'Please input your email address!',
                  },
                ]}
              >
                <Input type="email" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="phoneNumber" label="Primary Mobile Number">
                <Input
                  type="tel"
                  addonBefore={prefixSelector}
                  maxLength={10}
                  style={{ width: "100%" }}
                  required
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="status" label="Status">
                <Select placeholder="Choose a status" allowClear>
                  {statuses
                    ? statuses.map((status) => (
                        <Select.Option key={status.value} value={status.value}>
                          {status.text}
                        </Select.Option>
                      ))
                    : ""}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="type" label="Type">
                <Select
                  placeholder="Choose a type"
                  onChange={getType}
                  allowClear
                >
                  {types
                    ? types.map((type) => (
                        <Select.Option key={type} value={type}>
                          {type}
                        </Select.Option>
                      ))
                    : ""}
                </Select>
                {/* <Input disabled /> */}
              </Form.Item>
            </Col>
            {selectedInsurer?.type === "INSURER" ||
            selectedType === "INSURER" ? (
              <Col span={24}>
                <Form.Item name="insurerType" label="Insurer Type">
                  <Select placeholder="Choose an  insurer type" allowClear>
                    {insurerTypes
                      ? insurerTypes.map((type) => (
                          <Select.Option key={type} value={type}>
                            {type}
                          </Select.Option>
                        ))
                      : ""}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateInsurerModal;
