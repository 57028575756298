import {
  Row,
  Col,
  Typography,
  Card,
  DatePicker,
  Space,
  Statistic,
  Divider,
  Tag,
  Tooltip,
  notification
  ,
  Button
} from "antd";
import React, { useEffect, useState, useContext } from "react";
//context
import { AuthContext } from "../../context"
//custom css
import "./Overview.css";
import { getDashboardContents } from "../../http/image";
import { CompanyTypes } from '../../models/enum'
import { getDashboardContentsPerInsurer } from '../../http/image'
import { getDashboardContentsPerQuery } from '../../http/image'
const OverviewCard = ({ tickets, loading, setLoading }) => {
  const [ticketData, setTicketData] = useState([{}])
  const { user, accessToken } = useContext(AuthContext)


  const css = {
    fontWeight: "500",
    fontSize: "1.9em",
  };

  const handleReset = async () => {
    let res = []
    let query = {}
    try {
      setLoading(true)
      switch (user?.company?.type) {
        case CompanyTypes.insurer:
          res = await getDashboardContentsPerInsurer(
            accessToken,
            user?.company.id,
          )
          break

        case CompanyTypes.cmab:
          res = await getDashboardContents(accessToken)
          break

        case CompanyTypes.gia:
          query = {
            tier: 'TIER_2',
            // insurer: user?.company?.id,
          }

          res = await getDashboardContentsPerQuery(accessToken, query)
          // res = await getDashboardContentsPerInsurer(accessToken, user?.company?.id)
          break

        case CompanyTypes.nic:
          query = {
            tier: 'TIER_3',
          }
          // res = await getDashboardContentsPerInsurer(accessToken, user?.company?.id)
          res = await getDashboardContentsPerQuery(accessToken, query)
          break
        default:
          break
      }
      // const res =  await getDashboardContents(accessToken)
      if (res.data?.statusCode === 200) {
        console.log(res.data?.data);
        setTicketData(res.data?.data)
      }
    } catch (error) {
      return notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
      })
    } finally {
      setLoading(false)
    }
  }

  //fires when the dates are changed
  const handleDate = async (value) => {
  let res = []
  let query = {}
    if (value?.length === undefined) {
      setTicketData(tickets)
      return
    }
    //Value here is startDate and endDate based on range
    try {
      setLoading(true)
      switch (user?.company?.type) {
        case CompanyTypes.insurer:
          res = await getDashboardContentsPerInsurer(
            accessToken,
            user?.company.id,
            value[0], value[1]
          )
          break

        case CompanyTypes.cmab:
          res = await getDashboardContents(accessToken, value[0], value[1])
          break

        case CompanyTypes.gia:
          query = {
            tier: 'TIER_2',
            // insurer: user?.company?.id,
          }

          res = await getDashboardContentsPerQuery(accessToken, query, value[0], value[1])
          // res = await getDashboardContentsPerInsurer(accessToken, user?.company?.id)
          break

        case CompanyTypes.nic:
          query = {
            tier: 'TIER_3',
          }
          // res = await getDashboardContentsPerInsurer(accessToken, user?.company?.id)
          res = await getDashboardContentsPerQuery(accessToken, query, value[0], value[1])
          break
        default:
          break
      }
      if (res.data?.statusCode === 200) {
        console.log(res.data?.data);
        setTicketData(res.data?.data)
      }
    } catch (error) {
      return notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
      })
    } finally {
      setLoading(false)
    }



    // tickets.forEach((ticket) => {
    //   if (
    //     moment(ticket.createdAt) >= moment(value[0]) &&
    //     moment(ticket.createdAt) <= moment(value[1])
    //   ) {
    //     _data.push(ticket)
    //   }
    // });

  };

  useEffect(() => {
  
      setTicketData(tickets)
    
  }, [tickets])

  return (
    <>
      <Card className="overview__card" loading={loading}>
        <Row justify="space-between">
          <Col>
            <Typography.Title level={5} className="section__title">
              Overview
            </Typography.Title>
          </Col>
          <Row > 
          <Col className="ticket__space">
            <DatePicker.RangePicker onChange={handleDate}/>
          </Col>
          <Col>  <Button
                    type="primary"
                    className="custom__primary__button"
                    onClick={handleReset}
                >
                    Reset
                </Button> </Col>
          </Row>
        
               
            </Row>
        <Space className="stats__items" size={"small"}>
          <Statistic
            title="Total Tickets"
            value={ticketData[0]?.totalTickets}
            valueStyle={css}
          />
          <Divider type="vertical" className="divider" />
          <Statistic
            title="Opened Tickets"
            value={
              ticketData[0]?.openedTickets
            }
            valueStyle={css}
          />
          <Divider type="vertical" className="divider" />
          <Statistic
            title="Closed Tickets"
            value={ticketData[0]?.closedTickets}
            valueStyle={css}
          />
          <Divider type="vertical" className="divider" />
          <Statistic
            title="Avg Resolution Time"
            value={ticketData[0]?.resolutionTime}
            suffix={
              <>
                <div className="sub__details">
                  <sub className="sub__item">minutes</sub>
                  <div className="percentage__tag">
                    <Tooltip
                      title="compared to previous week"
                      placement="bottom"
                      color={"#009444"}
                    >
                      <Tag color={"red"}>-0.4%</Tag>
                    </Tooltip>
                  </div>
                </div>
              </>
            }
            valueStyle={css}
          />
          <Divider type="vertical" className="divider" />
          <Statistic
            title="Avg Customer Satisfaction"
            value={ticketData[0]?.customerSatisfaction}
            suffix={
              <>
                <div className="sub__details">
                  <sub className="sub__item">/10</sub>
                  <div className="percentage__tag">
                    <Tooltip
                      title="compared to previous week"
                      placement="bottom"
                      color={"#009444"}
                    >
                      <Tag color={"success"}>+0.4%</Tag>
                    </Tooltip>
                  </div>
                </div>
              </>
            }
            valueStyle={css}
          />
        </Space>
      </Card>
    </>
  );
};

export default OverviewCard;
