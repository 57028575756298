import { QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Spin,
  Tabs,
  Tag,
  Empty,
} from "antd";
import React, { useContext, useState } from "react";

//context
import { AuthContext } from "../../../context";

//http
import { escalateComplaint } from "../../../http/ticket";
import { CompanyTypes } from "../../../models/enum";

//custom css
import "../Modal.css";

const EscalateTicketModal = ({
  isEscalateModalVisible,
  setIsEscalateModalVisible,
  loading,
  setLoading,
  staffs,
  setStaffs,
  tempStaffs,
  companies,
  setCompanies,
  tempCompanies,
  setEscalated
}) => {
  const [form] = Form.useForm();
  const { selectedTicket, accessToken, user } = useContext(
    AuthContext
  );
// console.log(user)
  const [hidden, setHidden] = useState(true);
  const [externalHidden, setExternalHidden] = useState(true);

  const [selectedEmployee, setSelectedEmployee] = useState();
  const [selectedCompany, setSelectedCompany] = useState();

  // search for internal escalations
  const handleSearch = (e) => {
    let search = e.target.value.toLowerCase();

    setHidden(false);
    if (e.target.value === "") {
      setHidden(true);
      return setStaffs(tempStaffs);
    }

    const _staffs = staffs.filter((i) =>
      i.fullName.toLowerCase().includes(search)
    );
    return setStaffs(_staffs);
  };

  // search for external escalations (companies)
  const handleExternalSearch = (e) => {
    let search = e.target.value.toLowerCase();
    // console.log(search, companies);

    setExternalHidden(false);
    if (e.target.value === "") {
      setExternalHidden(true);
      setCompanies(tempCompanies);
      return;
    }

    const _companies = companies.filter((i) =>
      i.name.toLowerCase().includes(search)
    );
    // console.log(_companies);

    setCompanies(_companies);
  };

  const handleClose = () => {};

  //handle all escalations
  const handleSubmit = async (values) => {
    setLoading(true);
    let data = {
      reason: values.reason,
      complaint: selectedTicket?.id,
    };

    if (selectedEmployee) {
      data = {
        ...data,
        tier:
          selectedEmployee?.company?.type === CompanyTypes.insurer
            ? "TIER_1"
            : selectedEmployee?.company?.type === CompanyTypes.gia ||
              selectedEmployee?.company?.type === CompanyTypes.cmab
            ? "TIER_2"
            : "TIER_3",
        escalatedTo: selectedEmployee?.id,
      };
    } else {
      data = {
        ...data,
        tier:
          selectedCompany?.type === CompanyTypes.gia ||
          selectedCompany?.type === CompanyTypes.cmab
            ? "TIER_2"
            : "TIER_3",
        escalatedTo: selectedCompany?.id,
      };
    }

    try {
      // console.log(data);

      const res = await escalateComplaint(data, accessToken);
      if (res.data.data) {
        notification.success({
          message: "Ticket Escalation",
          description: `You have escalated Ticket ID ${
            res?.data.data[0].complaint.complaintNo
          } to ${
            selectedEmployee
              ? selectedEmployee?.fullName
              : selectedCompany?.name
          }.`,
        });
        setLoading(false);
        setEscalated(true);

        form.resetFields();
        setIsEscalateModalVisible(false);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: `${error.response.data.message}`,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={isEscalateModalVisible}
        title={<b>Escalate Ticket {selectedTicket?.complaintNo}</b>}
        onCancel={() => setIsEscalateModalVisible(false)}
        footer={[
          <Button
            type="primary"
            key="reset"
            ghost="true"
            className="custom__ghost__button"
            onClick={() => {
              setIsEscalateModalVisible(false);
              form.resetFields();
            }}
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            key="submit"
            htmlType="submit"
            className="custom__primary__button"
            onClick={form.submit}
            loading={loading}
          >
            Escalate to selected
          </Button>,
        ]}
      >
        <Tabs type="card" size="large">
          <Tabs.TabPane
            tab={
              <>
                <span style={{ paddingRight: "0.6em" }}>
                  Internal Escalation
                  <QuestionCircleOutlined style={{ marginLeft: "1em" }} />
                </span>
              </>
            }
            key="1"
          >
            <Form.Item>
              <div className="view" style={{ position: "relative" }}>
                {hidden ? <SearchOutlined className="search__icon" /> : ""}
                <input
                  type="search"
                  placeholder="Search by name"
                  className="customized__search__bar"
                  onChange={handleSearch}
                />
              </div>
            </Form.Item>

            {selectedEmployee ? (
              <div className="results">
                <Tag closable="true" onClose={handleClose}>
                  {selectedEmployee.fullName}
                </Tag>
              </div>
            ) : (
              ""
            )}
            <p>All Employees</p>
            <Spin spinning={loading}>
              <div className="search__results">
                {staffs.length > 0 ? (
                  staffs.map((staff) => (
                    <div key={staff.id}>
                      <Row
                        className={`search__result ${
                          staff.id === selectedEmployee?.id ? "selected" : ""
                        }`}
                        onClick={() => setSelectedEmployee(staff)}
                      >
                        <Col span={10}>
                          <div className="search__result__name">
                            <Avatar>
                              {staff?.fullName.match(/\b(\w)/g).join("")}
                            </Avatar>
                            <p>{staff?.fullName}</p>
                          </div>
                        </Col>
                        <Col span={10}>
                          <p style={{ float: "right" }}>{staff?.role?.replaceAll('_', ' ')}</p>
                        </Col>
                      </Row>
                    </div>
                  ))
                ) : (
                  <Empty />
                )}
              </div>
            </Spin>
            <div style={{ marginTop: "1em" }}>
              <Form layout="vertical" form={form} onFinish={handleSubmit}>
                <Form.Item name="reason" label="Reason" required>
                  <Input.TextArea></Input.TextArea>
                </Form.Item>
              </Form>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <>
                <span style={{ paddingRight: "0.6em" }}>
                  External Escalation
                  <QuestionCircleOutlined style={{ marginLeft: "0.3em" }} />
                </span>
              </>
            }
            key="2"
          >
            <Form.Item>
              <div className="view" style={{ position: "relative" }}>
                {externalHidden ? (
                  <SearchOutlined className="search__icon" />
                ) : (
                  ""
                )}
                <input
                  type="search"
                  placeholder="Search by name"
                  className="customized__search__bar"
                  onChange={handleExternalSearch}
                />
              </div>
              {selectedCompany ? (
                <div
                  className="results"
                  style={{ marginTop: "2em 0 0.2em 0 !important" }}
                >
                  <Tag closable="true">{selectedCompany.name}</Tag>
                </div>
              ) : (
                ""
              )}
              <p style={{ marginTop: "5px" }}>All Companies</p>
              <Spin spinning={loading}>
                <div className="search__results">
                  {companies.length > 0 ? (
                    (user?.company.type === 'INSURER' ? companies?.filter((com)=> com.type !== 'NIC') : companies).map((company) => (
                      <div key={company.id}>
                        <Row
                          className={`search__result ${
                            company.id === selectedCompany?.id ? "selected" : ""
                          }`}
                          onClick={() => setSelectedCompany(company)}
                        >
                          <Col span={10}>
                            <div className="search__result__name">
                              <Avatar>
                                {company?.name.match(/\b(\w)/g).join("")}
                              </Avatar>
                              <p>{company?.name}</p>
                            </div>
                          </Col>
                          <Col span={10}>
                            <p style={{ float: "right" }}>{company?.type}</p>
                          </Col>
                        </Row>
                      </div>
                    ))
                  ) : (
                    <Empty />
                  )}
                </div>
              </Spin>
              <div>
                <Form layout="vertical" form={form} onFinish={handleSubmit}>
                  <Form.Item name="reason" label="Reason" required>
                    <Input.TextArea></Input.TextArea>
                  </Form.Item>
                </Form>
              </div>
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default EscalateTicketModal;
