import React from 'react'
import { Modal, Col, Row, Button, Form, Input } from 'antd'
const ExportModal = ({ isExportModalVisible, setIsExportModalVisible, setToValue, setFromValue, handleExport, handleExportAll }) => {
  const [form] = Form.useForm() 

  return (
    <>
     <Modal
        title="Export table data"
        visible={isExportModalVisible}
        width={300}
        onCancel={()=> setIsExportModalVisible(false)}
        footer={[
            <>
                <Button
                    type="primary"
                    className="custom__primary__button"
                    onClick={handleExportAll}
                >
                    Export All 
                </Button>
                <Button
                    type="primary"
                    className="custom__primary__button"
                    onClick={handleExport}
                    >
                    Export 
                </Button>
            </>
        ]}
     >
        <Form
            layout="vertical"
            form={form}
        >
            <Row gutter="24">
                <Col span={12}>
                    <Form.Item name="from" label="From" required>
                        <Input required maxLength={4} onChange={(e) => setFromValue(parseInt(e.target.value))}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="to" label="To" required>
                        <Input required maxLength={4} onChange={(e) => setToValue(parseInt(e.target.value) + 1)}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal>   
    </>
  )
}

export default ExportModal