import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from "antd";
// import { RollbackOutlined } from "@ant-design/icons";

//http
import { updateStaff } from "../../../http/staff";

const UpdateStaffModal = ({
  isUpdateModalVisible,
  setIsUpdateModalVisible,
  selectedStaff,
  roles,
  user,
  accessToken,
  setUpdate,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 80 }} value="+233">
        <Select.Option value="+233">+233</Select.Option>
      </Select>
    </Form.Item>
  );

  // const generatePassword = () => {
  //   let pwdChars =
  //     "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  //   let pwdLen = 10;
  //   let randPassword = Array(pwdLen)
  //     .fill(pwdChars)
  //     .map(function (x) {
  //       return x[Math.floor(Math.random() * x.length)];
  //     })
  //     .join("");

  //   form.setFieldsValue({
  //     password: randPassword,
  //   });
  // };

  const handleSubmit = async (values) => {
    setLoading(true);
    const data = {
      fullName: values.fullName,
      // company:
      //   user?.company.type === "CMAB" ? values.company : user?.company.id,
      email: values.email,
      phoneNumber:
        values.phoneNumber.length > 10
          ? values.phoneNumber
          : "+233" + values.phoneNumber,
      department: values.department,
      role:
        (user?.sub || user?.id) !== selectedStaff.id
          ? values.role
          : selectedStaff.role,
      // accessLevel: values.accessLevel,
    };

    try {
      const res = await updateStaff(selectedStaff?.id, data, accessToken);
      if (res?.data.data?.length > 0) {
        notification.success({
          message: "Staff Updated",
          description: `${res?.data.data[0].fullName} has been updated successfully.`,
        });
      }
      setLoading(false);
      setUpdate(true);
    } catch (error) {
      setLoading(false);

      return notification.error({
        message: "Error",
        description: `${error.response.data.message}`,
      });
    }

    form.resetFields();
    setIsUpdateModalVisible(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      prefix: "+233",
      fullName: selectedStaff?.fullName,
      company: selectedStaff?.company?.id,
      email: selectedStaff?.email,
      phoneNumber: selectedStaff?.phoneNumber.substring(4),
      department: selectedStaff?.department,
      role: selectedStaff?.role,
      accessLevel: selectedStaff?.accessLevel,
    });
  }, [form, selectedStaff]);

  return (
    <>
      <Modal
        title={`Update ${
          (user?.sub || user?.id) === selectedStaff.id ? "Account" : "User"
        }`}
        visible={isUpdateModalVisible}
        confirmLoading={loading}
        onCancel={() => setIsUpdateModalVisible(false)}
        footer={[
          <Button
            type="primary"
            key="reset"
            ghost="true"
            className="custom__ghost__button"
            onClick={() => {
              setIsUpdateModalVisible(false);
              form.resetFields();
            }}
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            key="submit"
            htmlType="submit"
            className="custom__primary__button"
            onClick={form.submit}
            loading={loading}
          >
            Update{" "}
            {(user?.sub || user?.id) === selectedStaff.id ? "Account" : "Staff"}
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          scrollToFirstError
        >
          <Row gutter="24">
            <Col span={12}>
              <Form.Item name="fullName" label="Full Name" required>
                <Input required />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item name="company" label="Insurance Company" required>
                <Select placeholder="--- Select insurer ---" allowClear>
                  {companies
                    ? companies.map((company) => (
                        <Select.Option key={company.id} value={company.id}>
                          {company.name}
                        </Select.Option>
                      ))
                    : ""}
                </Select>
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item name="email" label="Email Address" required>
                <Input type="email" required />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phoneNumber"
                label="Primary Mobile Number"
                required
              >
                <Input
                  type="tel"
                  addonBefore={prefixSelector}
                  maxLength={9}
                  style={{ width: "100%" }}
                  required
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="department" label="Department">
                <Input />
              </Form.Item>
            </Col>
            {(user?.sub || user?.id) !== selectedStaff.id && (
              <Col span={12}>
                <Form.Item name="role" label="Role">
                  <Select placeholder="---Select ---" allowClear>
                    {roles
                      ? roles.map((role) => (
                          <Select.Option key={role.value} value={role.value}>
                            {role.text}
                          </Select.Option>
                        ))
                      : ""}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {/* {user?.company.type === "CMAB" ? (
              <Col span={12}>
                <Form.Item name="accessLevel" label="Access Level" required>
                  <Select placeholder="Choose a level" disabled allowClear>
                    {levels
                      ? levels.map((level) => (
                          <Select.Option key={level} value={level}>
                            {level}
                          </Select.Option>
                        ))
                      : ""}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              ""
            )} */}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateStaffModal;
