import { Avatar, Button, Col, Empty, Modal, Row, Tabs, Typography } from 'antd'
import React, { useContext } from 'react'

//context
import { AuthContext } from '../../../context'

const UpdateTicketModal = ({ isUpdateModalVisible, setUpdateModalVisible }) => {
  const { selectedTicket } = useContext(AuthContext)

  return (
    <>
      <Modal
        visible={isUpdateModalVisible}
        title={<b>Updates</b>}
        onCancel={() => setUpdateModalVisible(false)}
        footer={[
          <Button
            type="primary"
            className="custom__primary__button"
            onClick={() => setUpdateModalVisible(false)}
          >
            Close
          </Button>,
        ]}
      >
        <>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Escalations" key="1">
              {selectedTicket?.escalations.length > 0 ? (
                selectedTicket?.escalations.map((ticket) => (
                  <Row
                    justify="space-between"
                    style={{ marginBottom: '20px' }}
                    key={ticket.id}
                  >
                    <Col>
                      <div>
                        <p style={{ fontSize: '12px', color: '#616E73' }}>
                          {new Date(ticket.createdAt).toDateString()}
                        </p>
                        <Avatar className="avatar">JD</Avatar>
                        <Typography.Text style={{ marginLeft: '1em' }}>
                          {ticket.from}
                        </Typography.Text>
                      </div>
                    </Col>
                    <Col style={{ alignSelf: 'flex-end' }}>
                      <Typography.Text>
                        Escalated to {ticket.to}
                      </Typography.Text>
                    </Col>
                  </Row>
                ))
              ) : (
                <Empty />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Resolution" key="2">
              {selectedTicket?.resolvedBy ? (
                <Row>
                  <Col span={24}>
                    {selectedTicket?.resolvedBy && (
                      <>
                        Ticket <b>{selectedTicket?.complaintNo}</b> was resolved
                        by {selectedTicket?.assignedTo?.fullName}.
                        <div style={{ marginTop: '16px' }}>
                          <Typography.Text style={{ fontWeight: 'bold' }}>
                            Comment
                          </Typography.Text>
                          <p>{selectedTicket?.resolutionReason}</p>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              ) : (
                <Empty />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Ratings" key="3">
              {selectedTicket?.customerRating ? (
                selectedTicket?.customerRating
              ) : (
                <Empty />
              )}
            </Tabs.TabPane>
          </Tabs>
        </>
      </Modal>
    </>
  )
}

export default UpdateTicketModal
