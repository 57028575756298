import React, { useState } from "react";
import {
  Modal,
  Button,
  Col,
  Row,
  Form,
  Input,
  Select,
  notification,
  Tooltip,
} from "antd";
import { RollbackOutlined } from "@ant-design/icons";

//http
import { addStaff } from "../../../http/staff";
import { CompanyTypes } from "../../../models/enum";

const NewTicketModal = ({
  setIsModalVisible,
  isModalVisible,
  setStaffs,
  companies,
  roles,
  levels,
  accessToken,
  user,
}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 80 }} value="+233">
        <Select.Option value="+233">+233</Select.Option>
      </Select>
    </Form.Item>
  );

  const generatePassword = () => {
    let pwdChars =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let pwdLen = 10;
    let randPassword = Array(pwdLen)
      .fill(pwdChars)
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");

    form.setFieldsValue({
      password: randPassword,
    });
  };

  const handleCompanyChange = (value) => {
    const _companyIndex = companies.findIndex((i) => i.id === value);
    const _company = companies[_companyIndex];

    form.setFieldsValue({
      accessLevel:
        _company?.type === "INSURER"
          ? "TIER_1"
          : _company?.type === "GIA" || _company?.type === "CMAB"
          ? "TIER_2"
          : "TIER_3",
    });
  };

  const handleSubmit = async (values) => {
    let data = {};
    let res = [];

    setLoading(true);

    data = {
      fullName: values.fullName,
      email: values.email,
      password: values.password,
      phoneNumber: "+233" + values.phoneNumber,
      department: values.department,
      role: values.role,
      accessLevel:
        user?.company.type === "CMAB"
          ? values.accessLevel
          : user?.company.type === "INSURER"
          ? "TIER_1"
          : user?.company.type === "GIA"
          ? "TIER_2"
          : "TIER_3",
      company:
        user?.company.type === "CMAB" ? values.company : user?.company.id,
    };

    try {
      res = await addStaff(data, accessToken);

      if (res.data?.data.length > 0) {
        setStaffs((staffs) => [res?.data.data[0], ...staffs]);

        notification.success({
          message: "New user added",
          description: `${res?.data.data[0].fullName} has been added successfully.`,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      return notification.error({
        message: "Error",
        description: `${error.response.data.message}`,
      });
    }

    form.resetFields();
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Create new user"
        visible={isModalVisible}
        confirmLoading={loading}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button
            type="primary"
            key="reset"
            ghost="true"
            className="custom__ghost__button"
            onClick={() => {
              setIsModalVisible(false);
              form.resetFields();
            }}
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            key="submit"
            htmlType="submit"
            className="custom__primary__button"
            onClick={form.submit}
            loading={loading}
          >
            Add Staff
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          scrollToFirstError
          initialValues={{
            prefix: "+233",
          }}
        >
          <Row gutter="24">
            <Col span={12}>
              <Form.Item name="fullName" label="Full Name" required>
                <Input required />
              </Form.Item>
            </Col>
            {user?.company?.type === "CMAB" && (
              <Col span={12}>
                <Form.Item name="company" label="Insurance Company" required>
                  <Select
                    placeholder="--- Select insurer ---"
                    allowClear
                    onChange={handleCompanyChange}
                  >
                    {companies
                      ? companies.map((company) => (
                          <Select.Option key={company.id} value={company.id}>
                            {company.name}
                          </Select.Option>
                        ))
                      : ""}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item name="email" label="Email Address" required>
                <Input type="email" required />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="password" label="Password" required>
                <Input.Password
                  prefix={
                    <>
                      <Tooltip title="Click to generate password">
                        <RollbackOutlined onClick={() => generatePassword()} />
                      </Tooltip>
                    </>
                  }
                  required
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phoneNumber"
                label="Primary Mobile Number"
                required
              >
                <Input
                  type="tel"
                  addonBefore={prefixSelector}
                  maxLength={9}
                  style={{ width: "100%" }}
                  required
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="department" label="Department">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="role" label="Role">
                <Select placeholder="---Select ---" allowClear>
                  {roles
                    ? roles.map((role) => (
                        <Select.Option key={role.value} value={role.value}>
                          {role.text}
                        </Select.Option>
                      ))
                    : ""}
                </Select>
              </Form.Item>
            </Col>
            {user?.company.type === CompanyTypes.cmab && (
              <Col span={12}>
                <Form.Item name="accessLevel" label="Access Level" required>
                  <Select placeholder="Choose a level" disabled allowClear>
                    {levels
                      ? levels.map((level) => (
                          <Select.Option key={level} value={level}>
                            {level}
                          </Select.Option>
                        ))
                      : ""}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <>
              <Col span={12}>
                <Form.Item name="address" label="Address">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="city" label="City">
                  <Input />
                </Form.Item>
              </Col>
            </>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default NewTicketModal;
