/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState , useContext } from 'react'
import { Card, Col, DatePicker, Row, Tooltip, Typography, notification, Button } from 'antd'
import { AuthContext } from "../../context"
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts'
import { CompanyTypes } from '../../models/enum'
import { getDashboardContentsPerInsurer } from '../../http/image'
import { getDashboardContentsPerQuery } from '../../http/image'
import { getDashboardContents } from "../../http/image";
import './Overview.css'

const TicketTypeCard = ({ tickets, loading, setLoading }) => {
  const [ticketData, setTicketData] = useState([{}])
  const { user, accessToken } = useContext(AuthContext)

  const colors = ['#E4A84E', '#009444']
  // const types = [
  //   { name: 'Claim Tickets', value: 'CLAIM_INSURANCE' },
  //   { name: 'Non-Claim Tickets', value: 'NON_CLAIM_INSURANCE' },
  // ]

  //fires when the dates are changed
  const handleDate = async (value) => {
    let res = []
    let query = {}
      if (value?.length === undefined) {
        setTicketData(tickets)
        return
      }
      //Value here is startDate and endDate based on range
      try {
        setLoading(true)
        switch (user?.company?.type) {
          case CompanyTypes.insurer:
            res = await getDashboardContentsPerInsurer(
              accessToken,
              user?.company.id,
              value[0], value[1]
            )
            break
  
          case CompanyTypes.cmab:
            res = await getDashboardContents(accessToken, value[0], value[1])
            break
  
          case CompanyTypes.gia:
            query = {
              tier: 'TIER_2',
              // insurer: user?.company?.id,
            }
  
            res = await getDashboardContentsPerQuery(accessToken, query, value[0], value[1])
            // res = await getDashboardContentsPerInsurer(accessToken, user?.company?.id)
            break
  
          case CompanyTypes.nic:
            query = {
              tier: 'TIER_3',
            }
            // res = await getDashboardContentsPerInsurer(accessToken, user?.company?.id)
            res = await getDashboardContentsPerQuery(accessToken, query, value[0], value[1])
            break
          default:
            break
        }
        if (res.data?.statusCode === 200) {
          // console.log(res.data?.data);
          setTicketData(res.data?.data)
        }
      }  catch (error) {
      return notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
      })
    } finally {
      setLoading(false)
    }


  }
  const handleReset = async () => {
    
    let res = []
    let query = {}
    try {
      setLoading(true)
      switch (user?.company?.type) {
        case CompanyTypes.insurer:
          res = await getDashboardContentsPerInsurer(
            accessToken,
            user?.company.id,
          )
          break

        case CompanyTypes.cmab:
          res = await getDashboardContents(accessToken)
          break

        case CompanyTypes.gia:
          query = {
            tier: 'TIER_2',
            // insurer: user?.company?.id,
          }

          res = await getDashboardContentsPerQuery(accessToken, query)
          // res = await getDashboardContentsPerInsurer(accessToken, user?.company?.id)
          break

        case CompanyTypes.nic:
          query = {
            tier: 'TIER_3',
          }
          // res = await getDashboardContentsPerInsurer(accessToken, user?.company?.id)
          res = await getDashboardContentsPerQuery(accessToken, query)
          break
        default:
          break
      }
      // const res =  await getDashboardContents(accessToken)
      if (res.data?.statusCode === 200) {
        // console.log(res.data?.data);
        setTicketData(res.data?.data)
      }
    } catch (error) {
      return notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    

    setTicketData(tickets)
  }, [tickets])

  return (
    <>
      <Card className="ticket__type__card" loading={loading}>
        <Row justify="space-between"> 
        <Typography.Title level={5} className="section__title">
          Ticket Type
        </Typography.Title>
        <Col className='space__btn'>
        <Button
                    type="primary"
                    className="custom__primary__button"
                    onClick={handleReset}
                >
                    Reset
                </Button>
         </Col>
        
        </Row>
        
        <DatePicker.RangePicker onChange={handleDate} />
        <Row>
          <Col span={24}>
            <ResponsiveContainer minWidth={100} minHeight={300}>
              <PieChart width={600} height={400}>
                <Pie
                  data={ticketData[0]?.ticket}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={90}
                  fill="#82ca9d"
                  label
                >
                  {ticketData[0]?.ticket?.map((_, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default TicketTypeCard
