import { Table } from "antd";
import React from "react";
import { Capitalize } from "../../utils/capitalize";
import "./Table.css"

const ViewInsurersTable = ({
  insurers,
  selectedRowKeys,
  setSelectedRowKeys,
  setSelectedInsurer,
  loading,
}) => {

  const column = [ 
    {
      title: "Logo",
      dataIndex: "logo",
      key: "1",
      align: "center",
      ellipsis: true,
      render: (logo) => 
        <>{logo ? <img src={`${logo?.linkUrl || logo?.filename}`} alt="logo" style={{ height: "50px" }}/> : "N/A"}</>
      ,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "2",
      align: "center",
      ellipsis: true,
      render: (name) => <>{name ? name : "N/A"}</>,
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "3",
      align: "center",
      ellipsis: true,
      render: (email) => <>{email ? email : "N/A"}</>,
    },
    {
      title: "Mobile Number",
      dataIndex: "phoneNumber",
      key: "4",
      align: "center",
      ellipsis: true,
      render: (phoneNumber) => <>{phoneNumber ? phoneNumber : "N/A"}</>,
    },
    {
      title: "Status",
      dataIndex: "companyStatus",
      key: "5",
      align: "center",
      ellipsis: true,
      filters: [
        {
          text: "Good Standing",
          value: "GOOD_STANDING",
        },
        {
          text: "Administration",
          value: "ADMINISTRATION",
        },
      ],
      onFilter: (value, record) => record.companyStatus.indexOf(value) === 0,
      render: (companyStatus) => (
        <span>
          {companyStatus ? Capitalize(companyStatus) : 'N/A'}
        </span>
      ),
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "6",
    //   align: "center",
    //   filters: [
    //     {
    //       text: "Enabled",
    //       value: "ENABLED",
    //     },
    //     {
    //       text: "Disable",
    //       value: "DISABLED",
    //     },
    //   ],
    //   // render: (text, record, index) => (
    //   //   <>
    //   //     {record.isActive === true ? 
    //   //       <Button className="disable-btn">Disable</Button> : <Button className="enable-btn">Enable</Button>
    //   //     }
    //   //   </>
    //   // )
    // }
  ];
  
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedInsurer(selectedRows[0]);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  return (
    <>
      <Table
        rowSelection={{
          ...rowSelection,
        }}
        pagination={{ pageSize: 8 }}
        columns={column}
        dataSource={insurers}
        loading={loading}
        // rowClassName={companyState === "disabled" ? "disabledRow" : "enabledRow" }
      />
    </>
  );
};

export default ViewInsurersTable;
