/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Typography, notification } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

//components imports
import ChannelCard from '../components/Overview/ChannelCard'
// import CountDownCard from "../components/Overview/CountDownCard";
import OverviewCard from '../components/Overview/OverviewCard'
import TicketTypeCard from '../components/Overview/TicketTypeCard'

//context
import { AuthContext } from '../context'

//http
import { getDashboardContents } from '../http/image'
import { getDashboardContentsPerInsurer } from '../http/image'
import { getDashboardContentsPerQuery } from '../http/image'
import {
  getTickets,
  getTicketsPerInsurer,
  getTicketsPerQuery,
} from '../http/ticket'
import { CompanyTypes } from '../models/enum'

const Dashboard = () => {
  const { accessToken, user } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)
  const [loadingOverView, setLoadingOverview] = useState(false)
  const [loadingChannel, setLoadingChannel] = useState(false)
  const [tickets, setTickets] = useState([{}])

        
  useEffect(() => {
    const getTicketData = async () => {
      setLoading(true)
      setLoadingChannel(true)
      setLoadingOverview(true)
      let res = []
      let query = {}

      try {
        switch (user?.company?.type) {
          case CompanyTypes.insurer:
            res = await getTicketsPerInsurer(accessToken, user?.company.id)
            break

          case CompanyTypes.cmab:
            res = await getTickets(accessToken)
            break

          case CompanyTypes.gia:
            query = {
              tier: 'TIER_2',
              insurer: user?.company?.id,
            }

            res = await getTicketsPerQuery(accessToken, query)
            break

          case CompanyTypes.nic:
            query = {
              tier: 'TIER_3',
            }
            res = await getTicketsPerQuery(accessToken, query)
            break
          default:
            break
        }

         switch (user?.company?.type) {
          case CompanyTypes.insurer:
            res = await getDashboardContentsPerInsurer(
              accessToken,
              user?.company.id,
            )
            break

          case CompanyTypes.cmab:
            res = await getDashboardContents(accessToken)
            break

          case CompanyTypes.gia:
            query = {
              tier: 'TIER_2',
              // insurer: user?.company?.id,
            }

            res = await getDashboardContentsPerQuery(accessToken, query)
            // res = await getDashboardContentsPerInsurer(accessToken, user?.company?.id)
            break

          case CompanyTypes.nic:
            query = {
              tier: 'TIER_3',
            }
            // res = await getDashboardContentsPerInsurer(accessToken, user?.company?.id)
            res = await getDashboardContentsPerQuery(accessToken, query)
            break
          default:
            break
        }

        if (res.data?.statusCode === 200) {
          let ticketsSample = res.data?.data;
          let mainticket = ticketsSample;
          // console.log(mainticket);
           setTickets(mainticket)
        }
        setLoading(false)
        setLoadingChannel(false)
        setLoadingOverview(false)
      } catch (error) {
        notification.error({
          message: 'Error',
          description: `${error.response?.data.message}`,
        })
        setLoading(false)
        setLoadingChannel(false)
        setLoadingOverview(false)
      }
    }

    getTicketData()
  }, [user])

  return (
    <>
      <Row gutter={24}>
        <Col span={17}>
          <Typography.Title level={3}>Dashboard</Typography.Title>
          <OverviewCard tickets={tickets} loading={loadingOverView} setLoading={setLoadingOverview} />
          <ChannelCard
            tickets={tickets}
            loading={loadingChannel}
            setLoading={setLoadingChannel}
          />
        </Col>
        <Col span={7}>
          <TicketTypeCard
            tickets={tickets}
            loading={loading}
            setLoading={setLoading}
          />
          {/* <CountDownCard /> */}
        </Col>
      </Row>
    </>
  )
}

export default Dashboard
