/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, notification, PageHeader, Row, Select } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
//components
import NewInsurerModal from '../../components/Modals/Insurers/NewInsurerModal'
import UpdateInsurerModal from '../../components/Modals/Insurers/updateInsurerModal'
import ViewInsurersTable from '../../components/Tables/ViewInsurersTable'

//context
import { AuthContext } from '../../context'

//http
import { deleteInsurer, getInsurers } from '../../http/insurer'

//enums
import { CompanyTypes } from '../../models/enum'

const tabs = ['All', 'Active', 'Disabled']

const Index = () => {
  const { accessToken, user } = useContext(AuthContext)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [updateModalVisible, setUpdateModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [updated, setUpdated] = useState(false)
  const [selectedTab, setSelectedTab] = useState('All')

  const [insurers, setInsurers] = useState([])
  // const [tempInsurers, setTempInsurers] = useState([])
  const [selectedInsurer, setSelectedInsurer] = useState({})
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  /**
   * function for deleting insurer(s).
   *
   * @param {string} value
   */

   const getActiveInsurers = async () => {
    setLoading(true)
    try {
      const res = await getInsurers(accessToken, 'active')
      if (res.data?.data.length > 0) {
        // const activeInsurers = res.data.data.filter((d) => d.isActive === true)
        const activeInsurers = res.data.data;

        const _data = activeInsurers.map((insurer) => {
          return {
            ...insurer,
            key: insurer.id,
          }
        })
        setInsurers(_data.sort((a, b) => {
          return a.name.localeCompare(b.name)
        }))
        // setTempInsurers(_data.sort((a, b) => {
        //   return a.name.localeCompare(b.name)
        // }))
      }
      setLoading(false)
    } catch (error) {
      notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
      })
      setLoading(false)
    }
   }
   const getDisabledInsurers = async () => {
    setLoading(true)
    try {
      const res = await getInsurers(accessToken, 'inactive')
        // const activeInsurers = res.data.data.filter((d) => d.isActive === true)
        const activeInsurers = res?.data.data;
        const _data = activeInsurers?.map((insurer) => {
          return {
            ...insurer,
            key: insurer.id,
          }
        })

        setInsurers(_data.sort((a, b) => {
          return a.name.localeCompare(b.name)
        }))
        // setTempInsurers(_data.sort((a, b) => {
        //   return a.name.localeCompare(b.name)
        // }))
      setLoading(false)
    } catch (error) {
      notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
      })
      setLoading(false)
    }
   }
   
   const handleTabChange = async (tab) => {
    setSelectedTab(tab)
    // console.log("tab", tab)
    if(tab === 'All') {
      getData()
    }
    else if(tab === 'Active') {
      // const _insurers = tempInsurers.filter((i) => i.isActive === true)
      // setInsurers(_insurers)
      getActiveInsurers()
      // console.log("active", _insurers)
    }
    else if(tab === 'Disabled'){
      // const _insurers = tempInsurers.filter(
      //   (i) => i.isActive === false
      // )
      // setInsurers(_insurers)
      getDisabledInsurers()
      // console.log("inactive", _insurers)
    }
    else{
      return
    }
  }

  const handleChange = async (value) => {
    let res = {}

    if (value === 'Delete') {
      setLoading(true)
      try {
        for (const id in selectedRowKeys) {
          if (Object.hasOwnProperty.call(selectedRowKeys, id)) {
            const _id = selectedRowKeys[id]
            res = await deleteInsurer(_id, accessToken)
          }
        }

        if (res.data?.data.length > 0) {
          notification.success({
            message: 'Company Deleted',
            description: `${selectedRowKeys.length} company(s) deleted successfully.`,
          })
        }
        setLoading(false)
        setDeleted(true)
      } catch (error) {
        notification.error({
          message: 'Error',
          description: `${error.response.data.message}`,
        })
        setLoading(false)
      }
    } else {
      setUpdateModalVisible(true)
    }

    setSelectedRowKeys([])
  }

  /**
   * function to get all insurers
   *
   * @param {undefined} value
   */
  const getData = async () => {
    setLoading(true)
    try {
      const res = await getInsurers(accessToken)
      // console.log("res", res.data.data)
      if (res.data?.data.length > 0) {
        const _data = res.data.data.map((insurer) => {
          return {
            ...insurer,
            key: insurer.id,
          }
        })
        setInsurers(_data.sort((a, b) => {
          return a.name.localeCompare(b.name)
        }))
        // setTempInsurers(_data.sort((a, b) => {
        //   return a.name.localeCompare(b.name)
        // }))
      }

      setLoading(false)
      setDeleted(false)
      setUpdated(false)
    } catch (error) {
      notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
      })
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [deleted, updated, user])

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Companies"
        subTitle={
          <div style={{display: "flex"}}>
          <div className="tabs">
              {tabs.map((tab) => (
                <p
                  className={`user__type__tab ${
                    tab === selectedTab ? 'userTab__active' : ''
                  } `}
                  onClick={() => handleTabChange(tab)}
                >
                  {tab}
                </p>
              ))}
            </div>
            {selectedRowKeys.length === 1 ? (
              <Select placeholder="--- Apply ---" onChange={handleChange}>
                <>
                  <Select.Option value="Edit">Edit</Select.Option>
                  {/* <Select.Option value="Delete">Delete</Select.Option> */}
                </>
              </Select>
            ) : (
              ''
            )}
          </div>
        }
        extra={
          <>
            <Button
              type="primary"
              className="custom__primary__button"
              onClick={() => setIsModalVisible(true)}
            >
              Add new company
            </Button>
          </>
        }
      />
      <Row>
        <Col>
          <ViewInsurersTable
            insurers={insurers}
            setInsurers={setInsurers}
            accessToken={accessToken}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            loading={loading}
            setLoading={setLoading}
            setSelectedInsurer={setSelectedInsurer}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            setUpdated={setUpdated}
            getActiveInsurers={getActiveInsurers}
            getDisabledInsurers={getDisabledInsurers}
            // logoNotLoaded={logoNotLoaded}
          />
        </Col>
      </Row>
      {isModalVisible && (
        <NewInsurerModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          setInsurers={setInsurers}
          accessToken={accessToken}
          giaPresent={insurers.find(
            (insurer) => insurer.type === CompanyTypes.gia,
          )}
          nicPresent={insurers.find(
            (insurer) => insurer.type === CompanyTypes.nic,
          )}
        />
      )}
      {updateModalVisible && (
        <UpdateInsurerModal
          updateModalVisible={updateModalVisible}
          accessToken={accessToken}
          setUpdateModalVisible={setUpdateModalVisible}
          selectedInsurer={selectedInsurer}
          setUpdated={setUpdated}
        />
      )}
    </>
  )
}

export default Index
