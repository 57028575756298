import { InboxOutlined, PaperClipOutlined } from "@ant-design/icons"
import { Modal, Button, Form, Select, Card, Input, Row, Col, notification, DatePicker, Radio, Upload, Typography, Spin} from "antd"
import { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { AuthContext } from "../../../context"
import { editTicket } from "../../../http/ticket"
import { Roles } from "../../../models/enum"
import { countryCodes } from "../../../utils/countryCodes"
import moment from 'moment'
import { getFile } from "../../../utils/uploadFile"
import { getTicket } from "../../../http/ticket"
import { getInsurers } from "../../../http/insurer"
import PreviewDoc from "../../../utils/preview"
const EditTicketModal = ({ editOpen, setEditOpen, selectedTicketId }) => {
    const { user, accessToken } = useContext(AuthContext)
    const history = useHistory()
    const [editForm] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [ticket, setTicket] = useState(null)
    // const [updated, setUpdated] = useState(false)
    const [openPreview, setOpenPreview] = useState(false)
    // const [isResolvedModalVisible, setIsResolvedModalVisible] = useState(false)
    // const [isLiableModalVisible, setIsLiableModalVisible] = useState(false)
    // const [isNotLiableModalVisible, setIsNotLiableModalVisible] = useState(false)
    // const [liabilityDeterminationModalVisible, setLiabilityDeterminationModalVisible] = useState(false)

    console.log("selected", selectedTicketId)
    const [previewDoc, setPreviewDoc] = useState('')
  
    const [companies, setCompanies] = useState([])
    const [files, setFiles] = useState([])

    const style = { marginButton: '3em' }

    const handleClick = (e) => {
        e.preventDefault()
        history.push(`/tickets/edit/${selectedTicketId}`)
    }

    /**
   * handle the preview of docs available
   *
   * @param {string} doc
   */
  const handlePreview = (doc) => {
    setPreviewDoc(doc)
    setOpenPreview(true)
  }
  /**
   *
   * @param {fileObject} info
   */
  const handleDocumentChange = (info) => {
    let _data = []
    _data = info?.fileList.map((file) => {
      return {
        originFileObj: file?.originFileObj,
      }
    })
    setFiles(_data)
  }
  /**
   *
   * @param {string} value
   */
//   const handleChange = (value) => {
//     console.log(`selected ${value}`)
//     if (value === 'RESOLVED') {

//       setIsResolvedModalVisible(true)
//     }if (value === 'IN_REVIEW') {
//       // setIsLiableModalVisible(true)
//       handleStatusUpdate(value)
//     }if (value === 'PENDING_REVIEW') {
//       // setIsNotLiableModalVisible(true)
//       handleStatusUpdate(value)
//     }
//     if (value === 'LIABILITY_DETERMINATION') {
//       handleStatusUpdate(value)
//       // setLiabilityDeterminationModalVisible(true)
//     }
//     if (value === 'SETTLED') {
//       handleStatusUpdate(value)
//     }
//     else{
//       setSelectedTicket({
//         ...selectedTicket,
//         status: value
//       })
//     }
//   }

  useEffect(()=> {
    let componentMount = true
    if(componentMount){
      const getPageData = async () => {
        setLoading(true)
      try {
      const res = await getTicket(selectedTicketId, accessToken)
      if (res?.data?.data.length > 0) {
        setTicket(res.data?.data[0])
        let _selectedTicket = res.data.data[0]
      
        console.log(res.data.data[0])
        
        const names = _selectedTicket?.policyHolder?.fullName?.split(' ')
        editForm.setFieldsValue({
          type: _selectedTicket?.type,
          dateOfOccurrence: moment(_selectedTicket?.dateOfOccurrence),
          channel: _selectedTicket?.channel,
          insurer: _selectedTicket?.insurer?.id,
          fName: names[0],
          lName: names[1],
          phoneNumber: _selectedTicket?.policyHolder?.phoneNumber?.replace(+233|233, ' '),
          address: _selectedTicket?.policyHolder.address,
          city: _selectedTicket?.policyHolder.city,
          email: _selectedTicket?.policyHolder.email,
          policyNumber: _selectedTicket?.policyNumber,
          title: _selectedTicket?.title,
          description: _selectedTicket?.description,
          companyName: _selectedTicket?.companyName,
          companyEmail: _selectedTicket?.companyEmail,
          companyAddress: _selectedTicket?.companyAddress,
        })
        setLoading(false)
        // setUpdated(false)
      }
      } catch (error) {
      notification.error({
        message: 'Error',
        description: `Tickets ${error}`,
      })
      setLoading(false)
      }
      }
      getPageData()
    }
    return () => componentMount = false
  },[selectedTicketId, accessToken, editForm])

  /**
   * getting all companies
   */
   
  useEffect(() => {
    const getCompanies = async () => {
        try {
          const res = await getInsurers(accessToken)
          if (res?.data?.data?.length > 0) {
            setCompanies(res.data?.data)
          }
        } catch (error) {
          setLoading(false)
          notification.error({
            message: 'Error',
            description: `Companies ${error.response.data.message}`,
          })
        }
      }
    getCompanies()
  }, [accessToken])

//   const handleStatusUpdate = async (value) => {
//       setLoading(true)
//       console.log(value)
//       const data = {
//         status: value,
//         reason: value ?? '',
  
//       }
  
//       try {
//         //Resolve ticket here is a function that can be used to change any of the status of a ticket
//         const res = await resolveTicket(selectedTicketId, data, accessToken)
//         if (res.data.data.length > 0) {
//           setSelectedTicket(res.data.data[0])
//           notification.success({
//             message: 'Ticket Status Changed Successfully',
//             description: `You have updated Ticket ID ${res.data.data[0].complaintNo}..`,
//           })
//         }
//         setLoading(false)
//       } catch (error) {
//         setLoading(false)
  
//         return notification.error({
//           message: 'Error',
//           description: `${error}`,
//         })
//       }
//   }

//   const handleLiabilityChange = (value) => {
//     if (value === 'LIABLE') {
//       setIsLiableModalVisible(true)
//     }else if(value === 'NOT LIABLE') {
//       setIsNotLiableModalVisible(true)
//     }else{
//       return value
//     }
//   }
  /**
   * uploading files
   *
   * @param {object} files
   * @returns
   */
  const getDocuments = async (files) => {
    let documents = []
    let res = []

    for (const id in files) {
      if (Object.hasOwnProperty.call(files, id)) {
        const file = files[id]
        res = await getFile(file.originFileObj, setLoading, accessToken)
        if (res) {
          documents.push(res)
        }
      }
    }
    return documents
  }


    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select style={{ width: 120 }}
          disabled
          showSearch 
          allowClear
          optionFilterProp="label"
          filterOption={(input, option) =>
            option.children
              .toLowerCase()
              .includes(input.toLowerCase())
          } >
          {countryCodes.map((countryCode) => (
              <Select.Option value={'+' + countryCode.code} key={countryCode.iso}>
                {countryCode.iso + ' ' + countryCode.code}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )

      /**
   * editing selected ticket.
   *
   * @param {ticket-oject} values
   */
      const handleSubmit = async (values) => {
        const res = await getDocuments(files)
        setLoading(true)
    
        const policyHolder = {
          id: ticket?.policyHolder.id,
          address: values.address,
          city: values.city,
          email: values.email,
          fullName: values.fName + ' ' + values.lName,
          phoneNumber: values.phoneNumber,
        }
    
        const data = {
          channel: values.channel,
          dateOfOccurrence: values.dateOfOccurrence,
          description: values.description,
          insurer: values.insurer,
          policyNumber: values.policyNumber,
          title: values.title,
          type: values.type,
          policyHolder,
          documents: res,
          status: ticket?.status
        }
        // console.log("data", data)
        try {
          const res = await editTicket(selectedTicketId, data, accessToken)
          // console.log("res", res)
          if (res.data.data.length > 0) {
            notification.success({
              message: 'New Ticket edited',
              description: `${res?.data.data[0].complaintNo} has been added edited successfully.`,
            })
          }
          setLoading(false)
        //   setUpdated(true)
        } catch (error) {
          notification.error({
            message: 'Error',
            description: `${error.response.data.message}`,
          })
          setLoading(false)
        }
      }
      //added this request to override the default upload process of ant design upload component
      const dummyRequest = async ({ file, onSuccess }) => {    
        setTimeout(() => {
           onSuccess("ok");
        }, 0);
      }
    return(
        <>
            <Modal
                title="Edit Ticket Modal"
                visible={editOpen}
                onOk={handleSubmit}
                onCancel={() => setEditOpen(!editOpen)}
                footer={[
                    <Button key="back" type="danger" onClick={() => setEditOpen(!editOpen)}>
                        Cancel
                    </Button>,
                    // <Button key="submit" type="primary">
                    //     Submit
                    // </Button>,
                    <Button onClick={handleClick}>
                        View In Full To Perform More Actions
                    </Button>,
                ]}
                width={1200}
                style={{top: 0, right: 0, margin: "0px 0px 0px auto"}}
            >
            <Spin spinning={loading}>
                <Form
                    form={editForm}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={{
                        prefix: '+233',
                    }}
                    >
                    <Card>
                        <Row gutter={24}>
                        <Col style={style} span={8}>
                            <Form.Item label="Claim / General Enquiry" name="type">
                            <Select
                                placeholder="--- Select type ---"
                                disabled
                                allowClear
                                size='large'
                            >
                                <Select.Option value={'INDIVIDUAL'}>
                                Individual
                                </Select.Option>
                                <Select.Option value={'CORPORATE'}>
                                Corporate
                                </Select.Option>
                            </Select>
                            </Form.Item>
                        </Col>
                        {editForm.getFieldValue('type') === 'CORPORATE' && (
                            <>
                            <Col span={8}>
                                <Form.Item
                                label="Company Name"
                                name="companyName"
                                required
                                >
                                <Input
                                    placeholder="Company Name"
                                    className="input__group"
                                />
                                </Form.Item>
                            </Col>
                            <Col style={style} span={8}>
                                <Form.Item
                                label="Company Email Address"
                                name="companyEmail"
                                required
                                >
                                <Input
                                    type="email"
                                    placeholder="Email Address"
                                    className="input__group"
                                />
                                </Form.Item>
                            </Col>
                            <Col style={style} span={8}>
                                <Form.Item
                                label="Company Address"
                                name="companyAddress"
                                required
                                >
                                <Input
                                    placeholder="Address"
                                    className="input__group"
                                />
                                </Form.Item>
                            </Col>
                            </>
                        )}
                        <Col style={style} span={8}>
                            <Form.Item label="First Name" name="fName">
                            <Input
                                placeholder="First Name"
                                className="input__group"
                            />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Last Name" name="lName">
                            <Input placeholder="Last Name" className="input__group" />
                            </Form.Item>
                        </Col>
                        <Col style={style} span={8}>
                            <Form.Item label="Email Address" name="email">
                            <Input
                                type="email"
                                placeholder="Email Address"
                                className="input__group"
                                disabled
                            />
                            </Form.Item>
                        </Col>
                        <Col style={style} span={8}>
                            <Form.Item label="Phone Number" name="phoneNumber">
                            <Input
                                type="tel"
                                maxLength={9}
                                addonBefore={prefixSelector}
                                placeholder="2XXXXXXXXXX"
                                className="input__group"
                                disabled
                                size='large'
                            />
                            </Form.Item>
                        </Col>
                        <Col style={style} span={8}>
                            <Form.Item label="Address" name="address">
                            <Input placeholder="Address" className="input__group" />
                            </Form.Item>
                        </Col>
                        <Col style={style} span={8}>
                            <Form.Item label="City" name="city">
                            <Input placeholder="City" className="input__group" />
                            </Form.Item>
                        </Col>
                        <Col style={style} span={8}>
                            <Form.Item
                            label="Date of Occurrence"
                            name="dateOfOccurrence"
                            >
                            <DatePicker
                                disabled
                                placeholder="DD/MM/YY"
                                className="input__group"
                                required
                            />
                            </Form.Item>
                        </Col>
                        <Col style={style} span={8}>
                            <Form.Item label="Insurer of Concern" name="insurer">
                            <Select
                                placeholder="--- Select insurer ---"
                                disabled
                                allowClear
                                size='large'
                                showSearch
                                filterOption={(input, option) =>
                                option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())}
                            >
                                {companies.map((company) => (
                                <Select.Option value={company.id} key={company.id}>
                                    {company.name}
                                </Select.Option>
                                ))}
                            </Select>
                            </Form.Item>
                        </Col>
                        <Col style={style} span={8}>
                            <Form.Item label="Complaint title" name="title">
                            <Radio.Group>
                                <Radio value={'CLAIM_INSURANCE'}>Claim Insurance</Radio>
                                <Radio value={'NON_CLAIM_INSURANCE'}>
                                Non-Claim Insurance
                                </Radio>
                            </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col style={style} span={8}>
                            <Form.Item label="Complaint description" name="description">
                            <Input.TextArea rows={8} cols={24}></Input.TextArea>
                            </Form.Item>
                        </Col>
                        <Col style={style}>
                            <Form.Item label="Upload Documents" name="documents">
                            <Upload.Dragger
                                multiple={true}
                                onChange={handleDocumentChange}
                                style={{ padding: '2em' }}
                                customRequest={dummyRequest}
                            >
                                <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                                </p>
                                <p
                                className="ant-upload-text"
                                style={{ fontSize: '13px' }}
                                >
                                Click or drag file to this area to upload
                                </p>
                            </Upload.Dragger>
                            </Form.Item>
                        </Col>
                        <Col>
                        {ticket?.documents.length > 0 &&
                            <Typography.Title level={5}>
                                    Documents
                            </Typography.Title>
                        }
                            {ticket?.documents.length > 0 &&
                            ticket?.documents.map((doc, index) => (
                                <div key={index}>
                                <div
                                    onClick={() => handlePreview(doc?.linkUrl)}
                                    style={{ cursor: 'pointer', margin: '0 0 0.4em 0' }}
                                    key={doc.id}
                                >
                                    <div>
                                    <PaperClipOutlined style={{ color: '#1C4584' }} />
                                    </div>
                                    <Typography.Text style={{ color: '#1C4584' }}>
                                    {doc?.filename}
                                    </Typography.Text>
                                </div>
                                </div>
                            ))}
                        </Col>
                        </Row>
                    </Card>
                    <Row justify="end" style={{ padding: '2em 0' }}>
                        {user?.sub === ticket?.policyHolder?.id ||
                        (user?.role === Roles.admin && (
                            <Button
                            type="primary"
                            htmlType="submit"
                            className="custom__primary__button"
                            disabled={ticket?.resolvedBy ? true : false}
                            >
                            Save
                            </Button>
                        ))}
                    </Row>
                </Form> 
                </Spin>    
                {openPreview && (
                    <PreviewDoc setOpenPreview={setOpenPreview} doc={previewDoc} />
                )}  
            </Modal>
        </>
    )
}

export default EditTicketModal