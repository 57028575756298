import { SearchOutlined } from '@ant-design/icons'
import {
  Avatar,
  Button,
  Col,
  Empty,
  Form,
  Modal,
  notification,
  Row,
  Spin,
  Tag,
} from 'antd'
import React, { useState } from 'react'

//http
import { assignTicket } from '../../../http/ticket'
import { Roles } from '../../../models/enum'

//custom css
import '../Modal.css'

const AssignTicketModal = ({
  isAssignModalVisible,
  setAssignModalVisible,
  staffs,
  loading,
  setStaffs,
  tempStaffs,
  id,
  accessToken,
  setAssigned,
  user
}) => {
  const [hidden, setHidden] = useState(true)

  const [selectedEmployee, setSelectedEmployee] = useState()
  const [assignLoading, setAssignLoading] = useState(false)

  //exclude the user from the staffs available
  const AvailableStaffs = staffs.filter((staff)=> staff.fullName !== user?.fullName)
  

  const handleClose = () => {}

  //handle search of complaint handlers (staffs)
  const handleSearch = (e) => {
    let search = e.target.value.toLowerCase()

    setHidden(false)
    if (e.target.value === '') {
      setHidden(true)
      return setStaffs(tempStaffs)
    }

    const _staffs = AvailableStaffs.filter((i) =>
      i.fullName.toLowerCase().includes(search),
    )
    return setStaffs(_staffs)
  }

  //handle assignment of tickets
  const handleSubmit = async () => {
    setAssignLoading(true)
    const data = {
      assignTo: selectedEmployee.id,
      id,
    }

    try {
      const res = await assignTicket(data, accessToken)

      if (res.data?.data.length > 0) {
        notification.success({
          message: 'Ticket Assigned',
          description: `You have been assigned Ticket${res.data.data[0].complaintNo} to ${res.data.data[0].assignedTo?.fullName}`,
        })

        setAssignLoading(false)
        setAssignModalVisible(false)
        setAssigned(true)
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: `${error}`,
      })
      setAssignLoading(false)
    }
  }

  return (
    <Modal
      visible={isAssignModalVisible}
      title={<b>Assign Complaint Handler</b>}
      onCancel={() => setAssignModalVisible(false)}
      footer={[
        <Button
          type="primary"
          key="reset"
          ghost="true"
          className="custom__ghost__button"
          onClick={() => {
            setAssignModalVisible(false)
          }}
        >
          Cancel
        </Button>,
        <Button
          type="primary"
          key="submit"
          htmlType="submit"
          className="custom__primary__button"
          onClick={handleSubmit}
          loading={assignLoading}
        >
          Assign to selected
        </Button>,
      ]}
    >
      <Form.Item>
        <div className="view" style={{ position: 'relative' }}>
          {hidden ? <SearchOutlined className="search__icon" /> : ''}
          <input
            type="search"
            placeholder="Search by name"
            className="customized__search__bar"
            onChange={handleSearch}
          />
        </div>
      </Form.Item>

      {selectedEmployee ? (
        <div className="results">
          <Tag closable="true" onClose={handleClose}>
            {selectedEmployee.fullName}
          </Tag>
        </div>
      ) : (
        ''
      )}
      <p>All Employees</p>
      <Spin spinning={loading}>
        <div className="search__results">
          {AvailableStaffs.length > 0 ? (
            AvailableStaffs.map((staff) => (
              <>
                <Row
                  className={`search__result ${
                    staff.id === selectedEmployee?.id ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedEmployee(staff)}
                  key={staff.id}
                >
                  <Col span={10}>
                    <div className="search__result__name">
                      <Avatar>
                        {staff?.fullName.match(/\b(\w)/g).join('')}
                      </Avatar>
                      <p>{staff?.fullName}</p>
                    </div>
                  </Col>
                  <Col span={10}>
                    <p style={{ float: 'right' }}>
                      {staff?.role === Roles.admin
                        ? 'Admin'
                        : 'Complaint Handler'}
                    </p>
                  </Col>
                </Row>
              </>
            ))
          ) : (
            <Empty />
          )}
        </div>
      </Spin>
    </Modal>
  )
}

export default AssignTicketModal
