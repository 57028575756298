import axios from 'axios'

export const getStaffs = async (token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.get(`${process.env.REACT_APP_API_URL}/staffs`, config)

  return res
}

export const getAllStaffs = async (token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/staffs?all=true`,
    config,
  )

  return res
}

export const getStaff = async (id, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/staffs/${id}`,
    config,
  )

  return res
}

export const addStaff = async (data, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/staffs`,
    data,
    config,
  )

  return res
}

export const updateStaff = async (id, data, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.patch(
    `${process.env.REACT_APP_API_URL}/staffs/${id}`,
    data,
    config,
  )

  return res
}

export const deleteStaff = async (id, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.delete(
    `${process.env.REACT_APP_API_URL}/staffs/${id}`,
    config,
  )

  return res
}
