import {
  Modal,
  Button,
  Col,
  Row,
  Form,
  Input,
  Select,
  Upload,
  notification,
  Spin,
} from 'antd'
import React, { useState } from 'react'
import { FileImageOutlined } from '@ant-design/icons'

//http
import { addInsurer } from '../../../http/insurer'
import { uploadImage } from '../../../http/image'

const NewInsurerModal = ({
  setIsModalVisible,
  isModalVisible,
  setInsurers,
  accessToken,
  giaPresent,
  nicPresent,
}) => {
  const [form] = Form.useForm()

  const [imageLoading, setImageLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const [selectedType, setSelectedType] = useState()
  const [imageUrl, setImageUrl] = useState({})
  const [base64, setBase64] = useState('')
  const [data, setData] = useState({})
  // console.log("image url",imageUrl)
  console.log("data", data);
  const statuses = [
    {
      text: 'Good Standing',
      value: 'GOOD_STANDING',
    },
    {
      text: 'Administration',
      value: 'ADMINISTRATION',
    },
  ]
  const insurerTypes = ['LIFE', 'NONLIFE']
  let types = ['INSURER', 'GIA', 'NIC']

  const handleSubmit = async (values) => {
    let file = await getFile(imageUrl)
    console.log("file", file)
    const fileData = {
      filename: file.filename,
      linkUrl: file.linkUrl,
      size: parseInt(file.size),
    }
    const data = {
      name: values.name,
      email: values.email,
      phoneNumber: '+233' + values.phoneNumber,
      password: '',
      companyStatus: values.status,
      // type: 'INSURER',
      type: values.type,
      logo: fileData,
      insurerType: values.insurerType ? values.insurerType : null,
    }
    setData(data)
    setLoading(true)
    try {
      const res = await addInsurer(data, accessToken)
      if (res.data?.data.length > 0) {
        setInsurers((insurers) => [res?.data.data[0], ...insurers])

        notification.success({
          message: 'New Insurer added',
          description: `${res.data.data[0].name} has been added successfully.`,
        })
      }
      setLoading(false)
      setBase64('')
    } catch (error) {
      setLoading(false)
      return notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
      })
    }

    form.resetFields()
    setIsModalVisible(false)
  }

  const getFile = async (imageUrl) => {
    setImageLoading(true)

    const form = new FormData()
    form.append('file', imageUrl)

    try {
      const res = await uploadImage(form, accessToken)
      if (res.data?.data.length > 0) return res.data.data[0]
    } catch (error) {
      notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
      })
    } finally {
      setImageLoading(false)
    }
  }

  const handleImage = (info) => {
    setImageUrl(info.file?.originFileObj)

    getBase64(info.file?.originFileObj, (imageUrl) => setBase64(imageUrl))
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const getType = (value) => {
    setSelectedType(value)
  }

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 80 }} value="+233">
        <Select.Option value="+233">+233</Select.Option>
      </Select>
    </Form.Item>
  )

  //remove type nic and gia if they have been created already.
  types = types
    .filter((type) => type !== giaPresent?.type)
    .filter((type) => type !== nicPresent?.type)

  return (
    <>
      <Modal
        title="Create new company"
        visible={isModalVisible}
        confirmLoading={loading}
        width={450}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button
            type="primary"
            key="reset"
            ghost="true"
            className="custom__ghost__button"
            onClick={() => {
              setIsModalVisible(false)
              form.resetFields()
            }}
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            key="submit"
            htmlType="submit"
            className="custom__primary__button"
            onClick={form.submit}
            loading={loading}
          >
            Add Company
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            prefix: '+233',
          }}
          scrollToFirstError
        >
          <Row gutter="24">
            <Col span={12}>
              <Spin spinning={imageLoading}>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  onChange={handleImage}
                >
                  {base64 ? (
                    <img src={base64} alt="avatar" style={{ width: '100%' }} />
                  ) : (
                    <FileImageOutlined
                      style={{ color: '#1c4584', fontSize: '30px' }}
                    />
                  )}
                </Upload>
              </Spin>
            </Col>
            <Col span={12}>
              <p>
                *Should not be more than 3MB <br />
                *Should be in png/jpeg/jpg/svg/webp format <br />
              </p>
            </Col>
            <Col span={24}>
              <Form.Item name="name" label="Company Name" required>
                <Input required />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="email" label="Primary Email Address" required>
                <Input type="email" required />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="phoneNumber" label="Primary Mobile Number">
                <Input
                  type="tel"
                  addonBefore={prefixSelector}
                  maxLength={9}
                  style={{ width: '100%' }}
                  required
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="status" label="Status">
                <Select placeholder="Choose a status" allowClear>
                  {statuses
                    ? statuses.map((status) => (
                        <Select.Option key={status.value} value={status.value}>
                          {status.text}
                        </Select.Option>
                      ))
                    : ''}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="type" label="Type">
                <Select
                  placeholder="Choose a type"
                  onChange={getType}
                  allowClear
                >
                  {types
                    ? types.map((type) => (
                        <Select.Option key={type} value={type}>
                          {type}
                        </Select.Option>
                      ))
                    : ''}
                </Select>
                {/* <Input disabled /> */}
              </Form.Item>
            </Col>
            {selectedType === 'INSURER' ? (
              <Col span={24}>
                <Form.Item name="insurerType" label="Insurer Type">
                  <Select placeholder="Choose an  insurer type" allowClear>
                    {insurerTypes
                      ? insurerTypes.map((type) => (
                          <Select.Option key={type} value={type}>
                            {type}
                          </Select.Option>
                        ))
                      : ''}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              ''
            )}
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default NewInsurerModal
