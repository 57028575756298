import axios from 'axios'

export const addInsurer = async (data, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/companies`,
    data,
    config,
  )

  return res
}

export const getInsurers = async (token, query) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/companies?only=${query ?? 'all' } `,
    config,
  )

  return res
}

export const getInsurer = async (id, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/companies/${id}`,
    config,
  )

  return res
}

export const deleteInsurer = async (id, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.delete(
    `${process.env.REACT_APP_API_URL}/companies/${id}`,
    config,
  )

  return res
}

export const updateInsurer = async (id, data, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.patch(
    `${process.env.REACT_APP_API_URL}/companies/${id}`,
    data,
    config,
  )

  return res
}

export const activateInsurer = async (id, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.patch(
    `${process.env.REACT_APP_API_URL}/companies/${id}/activate`,
    {},
    config,
  )

  return res
}

export const deactivateInsurer = async (id, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const res = await axios.patch(
    `${process.env.REACT_APP_API_URL}/companies/${id}/deactivate`,
    {},
    config,
  )

  return res
}