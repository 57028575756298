/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Badge,
  Col,
  Divider,
  Dropdown,
  Layout,
  Menu,
  notification,
  PageHeader,
  Row,
  Spin,
  Space,
  Tooltip,
  Input
} from 'antd'
import {
  ArrowLeftOutlined,
  BellFilled,
  DownOutlined,
  LeftOutlined,
  UserOutlined,
  RightOutlined,
  DeleteFilled,
  EyeFilled,
  SearchOutlined
} from '@ant-design/icons'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import moment from 'moment'

//context
import { AuthContext } from '../../context'

//components
import UpdateStaffModal from '../Modals/Staffs/UpdateStaffModal'

//custom css
import './BaseLayout.css'
import { deleteNotification, getNotifications } from '../../http/image'

//search
// import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'
// import { InstantSearch, SearchBox } from 'react-instantsearch-dom'
// import 'instantsearch.css/themes/satellite.css'
import ResultTemplate from '../SearchResult'
import axios from 'axios'

// const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
//   server: {
//     apiKey: `${process.env.REACT_APP_API_TYPESENSE_API_KEY}`,
//     nodes: [
//       {
//         host: `${process.env.REACT_APP_API_TYPESENSE_NODE}`,
//         port: '443',
//         protocol: 'https',
//       },
//     ],
//   },
//   additionalSearchParameters: {
//     queryBy: 'title, description, complaintNo, policyHolder, policyNumber, insurer',
//   },
// })

/**
 *
 * @param {function} logout
 * @param {boolean} setIsUpdateModalVisible
 * @returns
 */
const menu = (logout, setIsUpdateModalVisible) => {
  return (
    <Menu>
      <Menu.Item key="0">
        <p onClick={() => setIsUpdateModalVisible(true)}>
          {' '}
          <UserOutlined /> Account
        </p>
      </Menu.Item>
      <Menu.Item key="1">
        <span onClick={() => logout()}>Logout</span>
      </Menu.Item> 
    </Menu>
  )
}

/**
 *
 * @param {object} dashboardData
 * @param {token} accessToken
 * @param {boolean} loading
 * @param {*} setLoading
 * @param {*} setDeleted
 * @returns
 */
const notificationMenu = (
  dashboardData,
  accessToken,
  loading,
  setLoading,
  setDeleted,
) => {
  const handleDelete = async (id) => {
    setLoading(true)

    try {
      const res = await deleteNotification(id, accessToken)
      if (res.data?.data?.length > 0) {
        notification.success({
          message: 'Notification Deleted',
          description: `Notification has been deleted successfully.`,
        })
      }
      setDeleted(true)
    } catch (error) {
      return notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Row style={{ width: '400px', padding: '15px' }}>
      <Row>
        <Col>
          <b>Notifications({dashboardData.length})</b>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
          {dashboardData ? (
            dashboardData.map((data, index) => (
              <div key={index}>
                <Row justify="space-between">
                  <Col span={17}>
                    <span>{data.body}.</span>
                    <p style={{ fontSize: '12px', color: '#616E73' }}>
                      {moment(data.createdAt).calendar()}
                    </p>
                  </Col>
                  <Col span={4}>
                    <Space direction="horizontal">
                      <Tooltip title="View ticket.">
                        <Link to={`/tickets/edit/${data.complaintID}`}>
                          <EyeFilled />
                        </Link>
                      </Tooltip>
                      <DeleteFilled
                        style={{ color: '#cf1322' }}
                        onClick={() => handleDelete(data.id)}
                      />
                    </Space>
                  </Col>
                </Row>
                <Divider />
              </div>
            ))
          ) : (
            <Row justify="center">
              <Spin />
            </Row>
          )}
        </div>
      </Spin>
    </Row>
  )
}

const TopNav = ({ isCollapsed, setIsCollapsed }) => {
  const location = useLocation()
  const history = useHistory()
  const { logout, user, accessToken, setUpdate } = useContext(AuthContext)

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [dashboardData, setDashboardData] = useState([])
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false)
  const [searchData, setSearchData] = useState([])
  const [query, setQuery] = useState("")
  const [display, setDisplay] = useState(false) 

  const keys = ["complaintNo", "policyHolder", "policyNumber"]
  const roles = [
    { text: 'Admin', value: 'ADMIN' },
    { text: 'Complaint Handler', value: 'COMPLAINT_HANDLER' },
  ]

  const className = !isCollapsed ? 'move__right' : ''

  const handleChange = (e) => {
    setDisplay(true)
    setQuery(e.target.value)
  }
  
  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const OnSearch = (searchData) => {
      return searchData.filter((item) => keys.some((key)=> key === "complaintNo" || key === "policyNumber" ? (item[key].toLowerCase().includes(query) || item[key].toUpperCase().includes(query)) : (item[key].fullName.toLowerCase().includes(query) || item[key].fullName.toUpperCase().includes(query) || capitalize(item[key].fullName).includes(query))))
  }
  // const filteredData = OnSearch(searchData)
  useEffect(()=>{
    const fetchSearchData = async () => {
      try{
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/complaints/search`)
        setSearchData(res.data.data)
      }catch{}
    }
    fetchSearchData()
  },[])
  
  // console.log("searchData", searchData, "search query", query)
  
  useEffect(() => {
    const getData = async () => {
      setLoading(true)

      try {
        const res = await getNotifications(accessToken)
        if (res.data?.data?.length > 0) {
          const _data = res.data.data.reverse()
          setDashboardData(_data)
        }
        setDeleted(false)
      } catch (error) {
        return notification.error({
          message: 'Error',
          description: `${error}`,
        })
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [deleted])

  useEffect(() => {
    if (dashboardData?.length > 0) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [dashboardData])
  return (
    <>
      <Layout.Header className="header" style={{ padding: 0 }}>
        <Row justify="space-between">
          {location.pathname === '/tickets/new' ||
          location.pathname.includes('/tickets/edit') ? (
            <Col span={6}>
              <PageHeader
                className="site-page-header"
                backIcon={
                  <>
                    <ArrowLeftOutlined
                      style={{
                        padding: '8px',
                        borderRadius: '5px',
                        backgroundColor: '#CED2D9',
                      }}
                    />
                  </>
                }
                onBack={() => history.goBack()}
                title="Back"
              />
            </Col>
          ) : (
            <>
              {React.createElement(isCollapsed ? RightOutlined : LeftOutlined, {
                className: `trigger ${className}`,
                onClick: () => {
                  setIsCollapsed(!isCollapsed)
                },
              })}
              <Col span={4} style={{ alignSelf: 'center'}}>
                <Avatar src={user?.company?.logo?.linkUrl} alt="company logo" shape="circle" size="large" className="avatar" style={{ marginLeft: '1.3em'}}/>
                <span
                  style={{ marginLeft: '0.8em'}}
                >
                  {user?.company?.name}
                </span>
              </Col>
              <Col span={12} style={{ alignSelf: 'center' }}>
                <Input size="large" placeholder="Search here..." prefix={<SearchOutlined />} onChange={handleChange}/>
                <ResultTemplate data={OnSearch(searchData)} query={query} display={display} setDisplay={setDisplay}/>
              </Col>
            </>
          )}
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <Badge
                color="#E4A84E"
                dot={show}
                style={{ marginTop: '2.3em', marginRight: '0.5em' }}
              >
                <Dropdown
                  overlay={notificationMenu(
                    dashboardData,
                    accessToken,
                    loading,
                    setLoading,
                    setDeleted,
                  )}
                  arrow="true"
                  trigger={['click']}
                >
                  <BellFilled style={{ fontSize: '1.2em' }} />
                </Dropdown>
              </Badge>
              {/* set avatar to company logo  */}
              <Avatar className="avatar" style={{ marginLeft: '1.3em' }}>
                {user?.fullName?.match(/\b(\w)/g).join('')}
              </Avatar>
              <Dropdown
                trigger={['click']}
                overlay={menu(logout, setIsUpdateModalVisible)}
              >
                <span
                  onClick={(e) => e.preventDefault()}
                  style={{ marginLeft: '0.8em', cursor: 'pointer' }}
                >
                  {user?.fullName} <DownOutlined />
                </span>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </Layout.Header>
      {isUpdateModalVisible && (
        <UpdateStaffModal
          isUpdateModalVisible={isUpdateModalVisible}
          setIsUpdateModalVisible={setIsUpdateModalVisible}
          selectedStaff={user}
          accessToken={accessToken}
          roles={roles}
          setUpdate={setUpdate}
        />
      )}
    </>
  )
}

export default TopNav
