/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react'
import { HashRouter, Switch } from 'react-router-dom'

//custom routes
import AuthRoute from './utils/authRoute'
import AdminRoute from './utils/adminRoute'

//auth
import Login from './pages/auth/Login'
import ForgotPassword from './pages/auth/ForgotPassword'
import ResetPassword from './pages/auth/ResetPassword'

//main dashboard page
import Dashboard from './pages/Dashboard'

//tickets
import Ticket from './pages/tickets/Index'
import CreateTicket from './pages/tickets/CreateTicket'
import EditCreate from './pages/tickets/EditTicket'

//staffs
import Staff from './pages/staffs/Index'

//companies
import Insurer from './pages/insurers/Index'

//utils && context
import { AuthContext } from './context'

//http
import { getStaff } from './http/staff'

function App() {
  const { setUser, setUpdate, update } = useContext(AuthContext)

  // getting details of authenticated user
  useEffect(() => {
    const token = localStorage.getItem('accessToken')
    const id = localStorage.getItem('id')

    if (token && id) {
      const getUserDetails = async () => {
        try {
          const res = await getStaff(id, token)
          setUser(res.data.data[0])
          setUpdate(false)
        } catch (error) {
          throw new Error(error)
        }
      }

      getUserDetails()
    }
  }, [update])

  return (
    <HashRouter>
      <Switch>
        <AuthRoute path="/login" component={Login} />
        <AuthRoute path="/forgot-password" component={ForgotPassword} />
        <AuthRoute path="/reset-password" component={ResetPassword} />

        <AdminRoute exact path="/" component={Dashboard} />

        <AdminRoute exact path="/tickets" component={Ticket} />
        <AdminRoute path="/tickets/new" component={CreateTicket} />
        <AdminRoute path="/tickets/edit/:ticketId" component={EditCreate} />

        <AdminRoute exact path="/staffs" component={Staff} />

        <AdminRoute path="/companies" component={Insurer} />
      </Switch>
    </HashRouter>
  )
}

export default App
