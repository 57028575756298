/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import "./Ticket.css"
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  PageHeader,
  Radio,
  Result,
  Row,
  Select,
  Upload,
} from "antd";
import moment from "moment";
import { InboxOutlined } from "@ant-design/icons";

//context
import { AuthContext } from "../../context";

//http
import { getInsurers } from "../../http/insurer";
import { createTicket } from "../../http/ticket";

//utils
import { getFile } from "../../utils/uploadFile";

//enums
import { Capitalize } from "../../utils/capitalize";
import { CompanyTypes, Roles } from "../../models/enum";

const CreateTicket = () => {
  const [createForm] = Form.useForm();
  const { accessToken, setStep, user } = useContext(AuthContext);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [disable, setDisable] = useState(false);

  // const [buttonText, setButtonText] = useState('Save and edit later')
  const [submitButtonText, setSubmitButtonText] = useState('Review')
  const [complaintNo, setComplaintNo] = useState('')
  const [complaintTitle, setComplaintTitle] = useState('')
  const [complaintType, setComplaintType] = useState('')
  const [review, setReview] = useState(false)
  const [companies, setCompanies] = useState([])
  const [files, setFiles] = useState([])
  const [telephone, setTelephone] = useState(null)
  const [insurerType, setInsurerType] = useState(null)
  const style = { marginButton: '3em' }

  //filter companies by insurerType
  const filteredCompanies = companies.filter((company) => company.insurerType === insurerType)

  const handleEdit = (e) => {
    e.preventDefault()
    setReview(false)
    setStep(0)
  }

  const today = new Date().toISOString().split("T")[0]
  /**
   * function to store selected files(s) in a state.
   *
   * @param {object} info
   */
  const handleDocumentChange = (info) => {
    let _data = [];
    _data = info?.fileList.map((file) => {
      return {
        originFileObj: file?.originFileObj,
      };
    });

    setFiles(_data);
  };
  /**
   * function to uploaded selected files in the state.
   *
   * @param {object} files
   * @returns
   */
  const getDocuments = async (files) => {
    let documents = [];
    let res = [];

    for (const id in files) {
      if (Object.hasOwnProperty.call(files, id)) {
        const file = files[id];
        res = await getFile(file.originFileObj, setLoading, accessToken);
        if (res) {
          documents.push(res);
        }
      }
    }

    return documents;
  };

  /**
   * function to handle
   *
   * @param {null}
   */
  // const handleDisable = () => {
  //   if (buttonText === "Save and edit later") {
  //     setDisable(true);
  //     setButtonText("Edit ticket details");
  //     setSubmitButtonText("Submit");
  //     setStep(1);
  //   }

  //   if (buttonText === "Edit ticket details") {
  //     setDisable(false);
  //     setButtonText("Save and edit later");
  //     setSubmitButtonText("Review");
  //     setStep(0);
  //   }
  // };

  //added this request to override the default upload process of ant design upload component
  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  /**
   *creating a new ticket.
   *
   * @param {ticket-object} values
   * @returns
   */
  const handleReview = () => {
      setReview(true)
      if(!sent){
        setStep(2)
      }else{
        setStep(0)
      }
  }
  const handleSubmit = async (values) => {
    let res = await getDocuments(files)

    setLoading(true);
    const policyHolder = {
      address: values.address,
      city: values.city,
      email: values.email,
      fullName: values.fName + " " + values.lName,
      phoneNumber: telephone,
    };

    let data = {
      channel: values.channel,
      dateOfOccurrence: values.dateOfOccurrence,
      description: values.description,
      insurer:
        user?.company.type === CompanyTypes.cmab
          ? values.insurer
          : user?.company.id,
      policyNumber: values.policyNumber,
      title: values.title,
      type: values.type,
      policyHolder,
      documents: res,
    };

    if (complaintType === "CORPORATE") {
      data = {
        ...data,
        companyName: values.companyName,
        companyEmail: values.companyEmail,
        companyAddress: values.companyAddress,
      };
    }

    try {
      const res = await createTicket(data, accessToken);

      if (res.data?.data.length > 0) {
        setComplaintNo(res.data.data[0].complaintNo);
        setSent(true);
      }
      setLoading(false);
      setStep(2)

    } catch (error) {
      setLoading(false);
      return notification.error({
        message: "Error",
        description: `${error.response.data.message}`,
      });
    }
    createForm.resetFields();
  };

  useEffect(() => {
    /**
     * getting all companies
     */
    const getCompanies = async () => {
      try {
        const res = await getInsurers(accessToken);
        if (res?.data?.data) {
          const _companies = res.data.data.filter(
            (i) => i.type !== CompanyTypes.cmab
          );
          setCompanies(
            _companies.sort((a, b) =>
              a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            )
          );
          // setCompanies(res.data.data)
        }
      } catch (error) {
        notification.error({
          message: "Error",
          description: `${error.response.data.message}`,
        });
      }
    };

    getCompanies();

    if (user?.role === Roles.complainant) {
      const fullName = user?.fullName.split(" ");

      createForm.setFieldsValue({
        fName: fullName[0],
        lName: fullName[1],
        email: user?.email,
        phoneNumber: user?.phoneNumber,
      });
    }
  }, []);

  // console.log(complaintTitle)
  return (
    <>
      {review === false ? 
        <>
          <PageHeader className="site-page-header" title="Create New Ticket">
            <p>Provide details to fill the new ticket form</p>
          </PageHeader>
          <Form
            form={createForm}
            layout="vertical"
            onFinish={handleReview}
            initialValues={{
              dateOfOccurrence: moment(new Date().toDateString()),
              channel: Capitalize("DASHBOARD"),
              prefix: "+233",
            }}
          >
            <Card loading={loading}>
              <Row gutter={24}>
                <Col style={style} span={8}>
                  <Form.Item label="Claim / General Enquiry" name="type" required>
                    <Select
                      placeholder="--- Select type ---"
                      allowClear
                      disabled={disable}
                      size="large"
                      onChange={(value) => setComplaintType(value)}
                    >
                      <Select.Option value={"INDIVIDUAL"}>
                        Individual
                      </Select.Option>
                      <Select.Option value={"CORPORATE"}>
                        Corporate
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {complaintType === "CORPORATE" && (
                  <>
                    <Col span={8}>
                      <Form.Item
                        label="Company Name"
                        name="companyName"
                        required
                      >
                        <Input
                          placeholder="Company Name"
                          className="input__group"
                          disabled={disable}
                          />
                      </Form.Item>
                    </Col>
                    <Col style={style} span={8}>
                      <Form.Item
                        label="Company Email Address"
                        name="companyEmail"
                        required
                        >
                        <Input
                          type="email"
                          placeholder="Email Address"
                          className="input__group"
                          disabled={disable}
                        />
                      </Form.Item>
                    </Col>
                    <Col style={style} span={8}>
                      <Form.Item
                        label="Company Address"
                        name="companyAddress"
                        required
                      >
                        <Input
                          placeholder="Address"
                          className="input__group"
                          disabled={disable}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col style={style} span={8}>
                  <Form.Item label="First Name" name="fName" required>
                    <Input
                      placeholder="First Name"
                      className="input__group"
                      disabled={disable}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Last Name" name="lName" required>
                    <Input
                      placeholder="Last Name"
                      className="input__group"
                      disabled={disable}
                    />
                  </Form.Item>
                </Col>
                <Col style={style} span={8}>
                  <Form.Item label="Email Address" name="email" required>
                    <Input
                      type="email"
                      placeholder="Email Address"
                      className="input__group"
                      disabled={disable}
                      />
                  </Form.Item>
                </Col>
                <Col style={style} span={8}>
                  <Form.Item label="Phone Number" name="phoneNumber" required>
                  <PhoneInput
                    country={'gh'}
                    value={telephone}
                    enableSearch={true}
                    inputProps={{
                      maxLength: 16
                    }}
                    onChange={(phone) => {
                      setTelephone(phone)
                    }}
                    inputStyle={{height: 55, width: "100%"}}
                    disabled={disable}
                  />
                  </Form.Item>
                </Col>
                <Col style={style} span={8}>
                  <Form.Item label="Address" name="address" required>
                    <Input
                      placeholder="Address"
                      className="input__group"
                      disabled={disable}
                    />
                  </Form.Item>
                </Col>
                <Col style={style} span={8}>
                  <Form.Item label="City" name="city" required>
                    <Input
                      placeholder="City"
                      className="input__group"
                      disabled={disable}
                    />
                  </Form.Item>
                </Col>
                <Col style={style} span={8}>
                  <Form.Item
                    label="Date of Occurrence"
                    name="dateOfOccurrence"
                    required={complaintTitle === "CLAIM_INSURANCE" ? true : false}
                  >
                    <DatePicker
                      placeholder="DD/MM/YY"
                      className="input__group"
                      required
                      disabledDate={d => d.isAfter(today)}
                      disabled={disable}
                      format="DD/MM/YY"
                    />
                  </Form.Item>
                </Col>
                <Col style={style} span={8}>
                  <Form.Item label="Channel" name="channel" required>
                    <Input className="input__group" disabled />
                  </Form.Item>
                </Col>
                {user?.company.type === 'CMAB' ? (
                  <>
                  <Col style={style} span={8}>
                    <Form.Item
                      label="Insurer Type"
                      name="insurer-type"
                      required
                    >
                      <Select
                        size="large"
                        placeholder="--- Insurer Type ---"
                        allowClear
                        disabled={disable}
                        showSearch
                        onChange={(value) => setInsurerType(value)}
                      >
                          <Select.Option value="LIFE">
                            Life
                          </Select.Option>
                          <Select.Option value="NONLIFE">
                            Non-Life
                          </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col style={style} span={8}>
                    <Form.Item
                      label="Insurer of Concern"
                      name="insurer"
                      required
                    >
                      <Select
                        size="large"
                        placeholder="--- Select insurer ---"
                        allowClear
                        disabled={disable}
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())}
                          >
                        {filteredCompanies.map((company) => (
                          <Select.Option value={company.id} key={company.id}>
                            {company.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
                ) : (
                  ''
                )}
                <Col style={style} span={8}>
                  <Form.Item label="Policy Number" name="policyNumber" required>
                    <Input
                      placeholder="XXXXXXXXX"
                      className="input__group"
                      disabled={disable}
                    />
                  </Form.Item>
                </Col>
                <Col style={style} span={8}>
                  <Form.Item label="Complaint title" name="title" required>
                    <Radio.Group disabled={disable} onChange={(e) => setComplaintTitle(e.target.value)}>
                      <Radio value={'CLAIM_INSURANCE'} key={1}>
                        Claim Insurance
                      </Radio>
                      <Radio value={'NON_CLAIM_INSURANCE'} key={2}>
                        Non-Claim Insurance
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col style={style} span={8}>
                  <Form.Item
                    label="Complaint description"
                    name="description"
                    required
                  >
                    <Input.TextArea
                      rows={6}
                      disabled={disable}
                    ></Input.TextArea>
                  </Form.Item>
                </Col>
                <Col style={style}>
                  <Form.Item
                    label="Upload Documents(optional)"
                    name="documents"
                  >
                    <Upload.Dragger
                      multiple="true"
                      onChange={handleDocumentChange}
                      style={{ padding: "2em" }}
                      disabled={disable}
                      customRequest={dummyRequest}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end" style={{ padding: '2em 0' }}>
                <Button
                  type="primary"
                  className="custom__primary__button"
                  htmlType="submit"
                >
                  {submitButtonText}
                </Button>
              </Row>
            </Card>
          </Form>
         </> : 
         <> 
          <PageHeader className="site-page-header" title="Create New Ticket">
            <p>Review of ticket form</p>
          </PageHeader>
          <Form
            form={createForm}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
              dateOfOccurrence: moment(new Date().toDateString()),
              channel: Capitalize('DASHBOARD'),
              prefix: '+233',
            }}
            className={sent ? "displayNone" : ""}
          >
            <Card loading={loading}>
              <Row gutter={24}>
                <Col style={style} span={8}>
                  <Form.Item label="Claim / General Enquiry" name="type" required>
                    <Select
                      placeholder="--- Select type ---"
                      allowClear
                      onChange={(value) => setComplaintType(value)}
                      defaultValue={complaintType}
                      disabled
                    >
                      <Select.Option value={'INDIVIDUAL'}>
                        Individual
                      </Select.Option>
                      <Select.Option value={'CORPORATE'}>
                        Corporate
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {complaintType === 'CORPORATE' && (
                  <>
                    <Col span={8}>
                      <Form.Item
                        label="Company Name"
                        name="companyName"
                        required
                        >
                        <Input
                          placeholder="Company Name"
                          className="input__group"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    {complaintType === "INDIVIDUAL" && (
                      <>
                        {" "}
                        <Col style={style} span={8}>
                          <Form.Item
                            label="Company Email Address"
                            name="companyEmail"
                          >
                            <Input
                              type="email"
                              placeholder="Email Address"
                              className="input__group"
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    <Col style={style} span={8}>
                      <Form.Item
                        label="Company Address"
                        name="companyAddress"
                        required
                      >
                        <Input
                          placeholder="Address"
                          className="input__group"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col style={style} span={8}>
                  <Form.Item label="First Name" name="fName" required>
                    <Input
                      placeholder="First Name"
                      className="input__group"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Last Name" name="lName" required>
                    <Input
                      placeholder="Last Name"
                      className="input__group"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col style={style} span={8}>
                  <Form.Item label="Email Address" name="email">
                    <Input
                      type="email"
                      placeholder="Email Address"
                      className="input__group"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col style={style} span={8}>
                  <Form.Item label="Phone Number" name="phoneNumber" required disabled>
                  <PhoneInput
                    country={'gh'}
                    value={telephone}
                    enableSearch={true}
                    inputProps={{
                      maxLength: 16
                    }}
                    onChange={(phone) => {
                      setTelephone(phone)
                    }}
                    inputStyle={{height: 55, width: "100%"}}
                    disabled
                  />
                  </Form.Item>
                </Col>
                <Col style={style} span={8}>
                  <Form.Item label="Address" name="address" required>
                    <Input
                      placeholder="Address"
                      className="input__group"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col style={style} span={8}>
                  <Form.Item label="City" name="city" required>
                    <Input
                      placeholder="City"
                      className="input__group"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col style={style} span={8}>
                  <Form.Item
                    label="Date of Occurrence"
                    name="dateOfOccurrence"
                    required
                  >
                    <DatePicker
                      placeholder="DD/MM/YY"
                      className="input__group"
                      required
                      disabled
                      format="DD/MM/YY"
                    />
                  </Form.Item>
                </Col>
                <Col style={style} span={8}>
                  <Form.Item label="Channel" name="channel" required>
                    <Input className="input__group" disabled />
                  </Form.Item>
                </Col>
                {user?.company.type === "CMAB" ? (
                  <>
                  <Col style={style} span={8}>
                    <Form.Item
                      label="Insurer Type"
                      name="insurer-type"
                      required
                    >
                      <Select
                        size="large"
                        placeholder="--- Insurer Type ---"
                        allowClear
                        disabled
                        showSearch
                        onChange={(value) => setInsurerType(value)}
                      >
                          <Select.Option value="LIFE">
                            Life
                          </Select.Option>
                          <Select.Option value="NONLIFE">
                            Non-Life
                          </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col style={style} span={8}>
                    <Form.Item
                      label="Insurer of Concern"
                      name="insurer"
                      required
                    >
                      <Select
                        size="large"
                        placeholder="--- Select insurer ---"
                        allowClear
                        disabled
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())}
                          >
                        {filteredCompanies.map((company) => (
                          <Select.Option value={company.id} key={company.id}>
                            {company.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
                ) : (
                  ""
                )}
                <Col style={style} span={8}>
                  <Form.Item label="Policy Number" name="policyNumber" required>
                    <Input
                      placeholder="XXXXXXXXX"
                      className="input__group"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col style={style} span={8}>
                  <Form.Item label="Complaint title" name="title" required>
                    <Radio.Group disabled>
                      <Radio value={"CLAIM_INSURANCE"} key={1}>
                        Claim Insurance
                      </Radio>
                      <Radio value={"NON_CLAIM_INSURANCE"} key={2}>
                        Non-Claim Insurance
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col style={style} span={8}>
                  <Form.Item
                    label="Complaint description"
                    name="description"
                    required
                  >
                    <Input.TextArea
                      rows={6}
                      disabled
                    ></Input.TextArea>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end" style={{ padding: "2em 0" }}> 
                  <Button
                  type="primary"
                  className="custom__primary__button"
                  style={{marginRight: 10}}
                  onClick={handleEdit}
                  >
                    Edit
                  </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="custom__primary__button"
                  >
                  {review  ?  "Submit" : submitButtonText}
                </Button>
            </Row>
            </Card> 
          </Form>
        </>
        }
        { sent &&  <Card>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: 0,
            }}
          >
            <Result
              status="success"
              title="Ticket Successfully Submitted!!"
              subTitle={
                <>
                  You have created a new ticket with ID
                  <span style={{ color: "#009444" }}>{complaintNo}</span>.
                </>
              }
              extra={[
                <Button
                  type="primary"
                  className="custom__ghost__button"
                  ghost="true"
                  key="console"
                  onClick={() => {
                    setStep(0);
                    setSent(false);
                    setDisable(false);
                    setSubmitButtonText("Review");
                  }}
                >
                  Create new ticket
                </Button>,
                <Button
                  type="primary"
                  className="custom__primary__button"
                  key="dashboard"
                  onClick={() => {
                    setStep(0);
                    history.push("/tickets");
                  }}
                >
                  Go to Dashboard
                </Button>,
              ]}
            />
          </div>
        </Card>
      }
    </>
  );
};

export default CreateTicket;
