import { InboxOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Modal, Row, Upload, notification } from 'antd'
import React, { useState } from 'react'
import { setLiabilityStatus } from '../../../http/ticket'
import { getFile } from '../../../utils/uploadFile'

const style = { marginButton: '3em' }

const NotLiableTicketModal = ({
  isNotLiableModalVisible,
  setIsNotLiableModalVisible,
  ticketId,
  accessToken,
  setSelectedTicket,
  selectedTicket
}) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])

  
  const handleDocumentChange = (info) => {
    let _data = []
    _data = info?.fileList.map((file) => {
      return {
        originFileObj: file?.originFileObj,
      }
    })
    setFiles(_data)
  }

  const getDocuments = async (files) => {
    let documents = []
    let res = []

    for (const id in files) {
      if (Object.hasOwnProperty.call(files, id)) {
        const file = files[id]
        res = await getFile(file.originFileObj, setLoading, accessToken)
        if (res) {
          documents.push(res)
        }
      }
    }
    return documents
  }

  const dummyRequest = async ({ file, onSuccess }) => {    
    setTimeout(() => {
       onSuccess("ok");
    }, 0);
  }

  const handleSubmit = async (values) => {
    const res = await getDocuments(files)
    setLoading(true)
    const data = {
      documents: res,
      status: 'NOT_LIABLE',
      reason: values.reason ?? ' ',
    }
    setSelectedTicket({
      ...selectedTicket,
      documents: res
    })
    setLoading(true)
   
    // console.log(res)
    try {
      const res = await setLiabilityStatus(ticketId, data, accessToken)
      if (res.data.data.length > 0) {
        setSelectedTicket(res.data.data[0])
        notification.success({
          message: 'Added Liabilty Status',
          description: `You have resolved Ticket ID ${res.data.data[0].complaintNo}..`,
        })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)

      return notification.error({
        message: 'Error',
        description: `${error}`,
      })
    }

    setIsNotLiableModalVisible(false)
  }

  return (
    <Modal
      visible={isNotLiableModalVisible}
      title={<b>Not Liable</b>}
      onCancel={() => setIsNotLiableModalVisible(false)}
      footer={[
        <Button
          type="primary"
          key="reset"
          ghost="true"
          className="custom__ghost__button"
          onClick={() => {
            setIsNotLiableModalVisible(false)
            form.resetFields()
          }}
        >
          Cancel
        </Button>,
        <Button
          type="primary"
          key="submit"
          htmlType="submit"
          className="custom__primary__button"
          onClick={form.submit}
          loading={loading}
        >
          Submit
        </Button>,
      ]}
    >
      <Row>
        <Col style={style} span={24}>
            <Form.Item label="Upload Documents" name="documents">
                <Upload.Dragger
                    multiple={true}
                    onChange={handleDocumentChange}
                    style={{ padding: '2em' }}
                    customRequest={dummyRequest}
                    >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p
                        className="ant-upload-text"
                        style={{ fontSize: '13px' }}
                    >
                        Click or drag file to this area to upload
                    </p>
                </Upload.Dragger>
            </Form.Item>
        </Col>
        <Col span={24}>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item label="Comments" name="reason">
              <Input.TextArea placeholder="Give comments..." rows={5} />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export default NotLiableTicketModal
