import { Layout, Steps } from "antd";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

//context
import { AuthContext } from "../../../context";

//custom css
import "../BaseLayout.css";


const TicketSideMenuEmpty = () => {
  const location = useLocation();
  const {  step } = useContext(AuthContext);

  return (
    <Layout.Sider className="ticket__side__menu" trigger={null} width={290}>
      <div className="ticket__details">
        <div>
          <p>
            Created by:{" "}
            
          </p>
          <p>
            Date:{" "}
            
          </p>
          <p>
            Status:{" "}
           
          </p>
        </div>
      </div>
      {location.pathname === "/tickets/new" ? (
        <div className="ticket__steps">
          <Steps direction="vertical" size="small" current={step}>
            <Steps.Step title="Create new ticket" />
            <Steps.Step title="Review and submit" />
          </Steps>
        </div>
      ) : (
        ""
      )}
    </Layout.Sider>
  );
};

export default TicketSideMenuEmpty;
