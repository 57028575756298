import React, { useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Row, Col, Form, Input, Button, notification, Typography } from 'antd'
import { LockOutlined, MailOutlined } from '@ant-design/icons'

//images
import File from '../../images/file.svg'
import Seek from '../../images/seek.svg'
import Logo from '../../images/logo.svg'
import Status from '../../images/status.svg'
import CMABLogo from '../../images/cmab logo-03.png'

//http
import { loginUser } from '../../http/auth'

//context
import { AuthContext } from '../../context'

//css
import './Auth.css'

const Login = () => {
  const [loading, setLoading] = useState(false)
  const { login } = useContext(AuthContext)
  const history = useHistory()

  /**
   *
   * @param {object} values
   * @returns
   */
  const onSubmit = async (values) => {
    setLoading(true)

    try {
      const res = await loginUser(values)
      login(res.data.data[0])

      setLoading(false)
      history.push('/')
      //reload page immediately after login to fetch current data
      window.location.reload()
    } catch (error) {
      setLoading(false)
      return notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
      })
    }
  }

  return (
    <div className="login__page">
      <Row style={{ height: '100vh' }}>
        <Col span={12} className="left__side">
          <div className="pattern__one"></div>
          <div className="pattern__two"></div>
          <div className="inner__container">
            <div style={{ width: 'fit-content' }}>
              <Row>
                <Col span={6} className="icon__container">
                  <img
                    src={File}
                    alt="account convert"
                    className="icon float-end"
                    style={{ padding: '1em 0.7em !important' }}
                  />
                </Col>
                <Col span={12}>
                  <h2 style={{ color: '#fff', margin: '0' }}>
                    File/Lodge a Complaint
                  </h2>
                  <p style={{ opacity: '0.5' }}>
                    Every complaint matters. File any insurance complaint,
                    whether life or non-life Insurance Company
                  </p>
                </Col>
              </Row>
              <Row style={{ margin: '6em 0' }}>
                <Col span={6} className="icon__container">
                  <img src={Status} alt="ticket" className="icon float-end" />
                </Col>
                <Col span={12}>
                  <h2 style={{ color: '#fff', margin: '0' }}>Check Status</h2>
                  <p style={{ opacity: '0.5' }}>
                    Check the status of your lodged complaint.
                  </p>
                </Col>
              </Row>
              <Row style={{ marginTop: '6em 0' }}>
                <Col span={6} className="icon__container">
                  <img src={Seek} alt="" className="icon float-end" />
                </Col>
                <Col span={12}>
                  <h2 style={{ color: '#fff', margin: '0' }}>
                    Seek Insurance Advice
                  </h2>
                  <p style={{ opacity: '0.5' }}>
                    Get professional insurance advice from our team.
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col span={12} className="right__side">
          <div className="right__div">
            <img src={CMABLogo} alt="cmab-logo" />
            <h1 style={{ fontWeight: 'bold', marginTop: '1.3em' }}>
              Welcome back
            </h1>
            <p>Enter your credentials to access your account</p>
            <Form layout="vertical" onFinish={onSubmit} className="login__form">
              <div style={{ margin: '4em 0' }}>
                <Form.Item
                  name="username"
                  hasFeedback="true"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    prefix={
                      <>
                        <MailOutlined
                          style={{
                            color: '#CBCECF',
                            marginRight: '0.3em',
                            fontSize: '1.2em',
                          }}
                        />
                      </>
                    }
                    type="email"
                    placeholder="Email Address"
                    className="input__group"
                    required
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.Password
                    prefix={
                      <>
                        <LockOutlined
                          style={{
                            color: '#CBCECF',
                            marginRight: '0.3em',
                            fontSize: '1.2em',
                          }}
                        />
                      </>
                    }
                    placeholder="Password"
                    className="input__group"
                    required
                  />
                </Form.Item>
              </div>
              <Button
                type="primary"
                htmlType="submit"
                className="login__button"
                size="large"
                loading={loading}
              >
                Login
              </Button>
              <p>
                Forget your password?
                <Link to="/forgot-password">
                  <Typography.Text
                    className="forgot__password"
                    style={{ fontWeight: '500', marginLeft: '5px' }}
                  >
                    Reset here
                  </Typography.Text>
                </Link>
              </p>
            </Form>
            <div className="logo">
              <p>
                Powered by <img src={Logo} alt="cmab" height="15px" />
              </p>
              <span>{process.env.REACT_APP_VERSION}</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Login
