import React, { useRef} from 'react'
import { useOnClickOutside } from '../hooks/useOnClickOutside'
import { Link } from 'react-router-dom'
import "./component.css"
import { Tabs } from 'antd'

const ResultTemplate = ({ data, query, display, setDisplay }) => {
  const searchRef = useRef()

  useOnClickOutside(searchRef, () => {
    setDisplay(false)
  })

  return (
    <div
      style={{
        width: '100%',
        maxHeight: '300px',
        zIndex: 100000,
        background: '#fff',
        padding: '10px',
        position: 'absolute',
        marginTop: '4px',
        overflowY: 'scroll',
        boxShadow: 'rgba(17, 12, 46, 0.15) 0px 3px 5px 0px',
      }}
      className={display ? "display" : "not-display"}
      ref={searchRef}
    >

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Complaint Number" key="1">
          <div className="tabContentContainer">
            {data.length > 0 && query.length > 0 ? (
              data.map((hit) => (
                <Link
                  to={`/tickets/edit/${hit.id}`}
                  key={hit.objectID}
                  style={{ padding: 0, color: '#000' }}
                >
                  <p className="tabContent" key={hit.objectID}>{hit.complaintNo}</p>    
                </Link>
              ))
            ) : (
              <>No search results ...</>
            )}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Complainant Name" key="2">
          <div className="tabContentContainer">
            {data.length > 0 && query.length > 0 ? (
              data.map((hit) => (
                <Link
                  to={`/tickets/edit/${hit.id}`}
                  key={hit.objectID}
                  style={{ padding: 0, color: '#000' }}
                >
                  <p className="tabContent" key={hit.objectID}>{hit.policyHolder.fullName}</p>    
                </Link>
              ))
            ) : (
              <>No search results ...</>
            )}
           </div> 
        </Tabs.TabPane>
        <Tabs.TabPane tab="Policy Number" key="3">
          <div className="tabContentContainer">
            {data.length > 0 && query.length > 0 ? (
              data.map((hit) => (
                <Link
                  to={`/tickets/edit/${hit.id}`}
                  key={hit.objectID}
                  style={{ padding: 0, color: '#000' }}
                >
                  <p className="tabContent" key={hit.objectID}>{hit.policyNumber}</p>    
                </Link>
              ))
            ) : (
              <>No search results ...</>
            )}
           </div> 
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
export default ResultTemplate