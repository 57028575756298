import React, { useState } from 'react'
import { Layout } from 'antd'
import { useLocation } from 'react-router-dom'

import SideMenu from './SideMenu'
import TopNav from './TopNav'
import TicketSideMenuEmpty from './Ticket/TicketSideMenuEmpty'
import TicketSideMenu from './Ticket/TicketSideMenu'

const BaseLayout = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const location = useLocation()

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {
        (location.pathname === '/tickets/new')  ? (
          <TicketSideMenuEmpty />
        ):
      (
      location.pathname.includes('/tickets/edit') ? (
        <TicketSideMenu />
      ) : (
        <SideMenu isCollapsed={isCollapsed} />
      ))
      }

      <Layout className="inner__layout">
        <TopNav isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
        <Layout.Content className="content">{children}</Layout.Content>
      </Layout>
    </Layout>
  )
}

export default BaseLayout
