import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { AuthContext } from '../context'

function AuthRoute({ component: Component, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext)

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <Redirect to="/" />
        } else {
          return <Component {...props} />
        }
      }}
    />
  )
}

export default AuthRoute
