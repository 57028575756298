import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'

import BaseLayout from '../components/BaseLayout/Layout'
import { AuthContext } from '../context'

const AdminRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useContext(AuthContext)
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (isAuthenticated) {
          return (
            <BaseLayout>
              <Component {...routeProps} />
            </BaseLayout>
          )
        } else {
          return <Redirect to="/login" />
        }
      }}
    />
  )
}

export default AdminRoute
