import React from "react";

export const Account = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.761"
      height="22.761"
      viewBox="0 0 22.761 22.761"
    >
      <path
        id="account"
        d="M15.381,4A5.69,5.69,0,1,1,9.69,9.69,5.69,5.69,0,0,1,15.381,4m0,14.226c6.288,0,11.381,2.546,11.381,5.69v2.845H4V23.916C4,20.772,9.093,18.226,15.381,18.226Z"
        transform="translate(-4 -4)"
        fill="#fff"
      />
    </svg>
  );
};

export const Ticket = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.761"
      height="18.209"
      viewBox="0 0 22.761 18.209"
    >
      <path
        id="ticket-account"
        d="M73.485,115.1a2.276,2.276,0,0,0,2.276,2.276v4.552a2.276,2.276,0,0,1-2.276,2.276H55.276A2.276,2.276,0,0,1,53,121.933v-4.552a2.276,2.276,0,1,0,0-4.552v-4.552A2.275,2.275,0,0,1,55.276,106H73.485a2.276,2.276,0,0,1,2.276,2.276v4.552a2.276,2.276,0,0,0-2.276,2.276M69.5,119.941c0-1.707-3.414-2.561-5.121-2.561s-5.121.854-5.121,2.561v.854H69.5v-.854m-5.121-4.552a2.561,2.561,0,1,0-2.561-2.561A2.561,2.561,0,0,0,64.381,115.389Z"
        transform="translate(-53 -106)"
        fill="#fff"
      />
    </svg>
  );
};

export const ShieldAccount = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.761"
      height="27.819"
      viewBox="0 0 22.761 27.819"
    >
      <path
        id="shield-account"
        d="M14.381,1,3,6.058v7.587c0,7.018,4.856,13.581,11.381,15.174,6.525-1.593,11.381-8.156,11.381-15.174V6.058L14.381,1m0,5.058a3.794,3.794,0,1,1-3.794,3.794,3.794,3.794,0,0,1,3.794-3.794m6.487,15.174a12.253,12.253,0,0,1-6.487,4.957,12.253,12.253,0,0,1-6.487-4.957,12.854,12.854,0,0,1-1.1-1.935c0-2.086,3.427-3.794,7.587-3.794s7.587,1.669,7.587,3.794A12.854,12.854,0,0,1,20.867,21.232Z"
        transform="translate(-3 -1)"
        fill="#fff"
        opacity="0.4"
      />
    </svg>
  );
};

export const ChartBar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.761"
      height="20.485"
      viewBox="0 0 22.761 20.485"
    >
      <path
        id="chart-bar-stacked"
        d="M24.761,23.485H2V3H4.276V21.209H6.552V18.933H11.1v2.276h2.276V17.795h4.552v3.414h2.276V18.933h4.552v4.552m-4.552-7.966h4.552v2.276H20.209V15.519m-6.828-9.1h4.552V9.828H13.381V6.414m4.552,10.242H13.381v-5.69h4.552v5.69M6.552,10.966H11.1v2.276H6.552V10.966M11.1,17.795H6.552V14.381H11.1Z"
        transform="translate(-2 -3)"
        fill="#fff"
      />
    </svg>
  );
};
