import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Avatar, Layout, Menu } from "antd";
import Icon from "@ant-design/icons";

//images
import Logo from "../../images/logo.jpeg";
// import ChartBar from '../../images/chart-bar-stacked.svg'
// import Ticket from '../../images/ticket-account.svg'
// import ShieldAccount from '../../images/shield-account.svg'
// import Account from '../../images/account.svg'

//custom svgs
import { Account, ChartBar, ShieldAccount, Ticket } from "../Svgs";

//context
import { AuthContext } from "../../context";

//custom css
import "./BaseLayout.css";
import { CompanyTypes } from "../../models/enum";

const AccountIcon = (props) => <Icon component={Account} {...props} />;
const ChartIcon = (props) => <Icon component={ChartBar} {...props} />;
const ShieldAccountIcon = (props) => (
  <Icon component={ShieldAccount} {...props} />
);
const TicketIcon = (props) => <Icon component={Ticket} {...props} />;

const SideMenu = ({ isCollapsed }) => {
  const { user } = useContext(AuthContext);

  return (
    <Layout.Sider
      className="side__menu"
      trigger={null}
      collapsible
      collapsed={isCollapsed}
    >
      <div className="custom__logo">
        <Avatar style={{ float: "left" }} src={Logo} size="large" />
      </div>
      <Menu className="menu" mode="inline">
        {/* <Menu.Item key={1} icon={<img src={ChartIcon} alt="chart-bar" />}> */}
        <Menu.Item key="1" icon={<ChartIcon style={{ color: "#33f" }} />}>
          Dashboard
          <NavLink to="/" />
        </Menu.Item>
        {/* <Menu.Item key={2} icon={<img src={<TicketIcon/>} alt="ticket" />}> */}
        <Menu.Item key="2" icon={<TicketIcon />}>
          Tickets
          <NavLink to="/tickets" />
        </Menu.Item>
        {user?.company?.type === CompanyTypes.cmab && (
          <Menu.Item
            key="3"
            // icon={<img src={<ShieldAccountIcon/>} alt="shield-account" />}
            icon={<ShieldAccountIcon />}
          >
            Companies
            <NavLink to="/companies" />
          </Menu.Item>
        )}
        <Menu.Item key="4" icon={<AccountIcon />}>
          {/* <Menu.Item key={4} icon={<img src={AccountIcon} alt="account" />}> */}
          Users
          <NavLink to="/staffs" />
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
};

export default SideMenu;
