import axios from "axios";

export const createTicket = async (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/complaints`,
    data,
    config
  );

  return res;
};

export const getTickets = async (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/complaints`,
    config
  );

  return res;
};

export const getTicketsPerInsurer = async (token, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/complaints?insurer=${id}`,
    config
  );

  return res;
};

export const getTicketsPerQuery = async (token, query) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/complaints?tier=${query.tier}`,
    config
  );

  return res;
};

export const getTicket = async (id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/complaints/${id}`,
    config
  );

  return res;
};

export const editTicket = async (id, data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.patch(
    `${process.env.REACT_APP_API_URL}/complaints/${id}`,
    data,
    config
  );

  return res;
};

export const deleteTicket = async (id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.delete(
    `${process.env.REACT_APP_API_URL}/complaints/${id}`,
    config
  );

  return res;
};

export const assignTicket = async (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/complaints/assign`,
    data,
    config
  );

  return res;
};

export const resolveTicket = async (id, data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(
    `${process.env.REACT_APP_API_URL}/complaints/${id}/status`,
    data,
    config
  );

  return res;
};
export const setLiabilityStatus = async (id, data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(
    `${process.env.REACT_APP_API_URL}/complaints/${id}/liability-status`,
    data,
    config
  );

  return res;
};

export const escalateComplaint = async (data, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/complaints/escalate`,
    data,
    config
  );

  return res;
};

export const getEscalatedComplaint = async (id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/complaints/escalate/${id}`,
    config
  );

  return res;
};
