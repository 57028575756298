/* eslint-disable react-hooks/exhaustive-deps */
import { notification, Table } from 'antd'
import React, { useEffect, useState } from 'react'

//http
import { getAllStaffs, getStaffs } from '../../http/staff'
import { CompanyTypes } from '../../models/enum'
import { Capitalize } from '../../utils/capitalize'

const ViewStaffsTable = ({
  staffs,
  setStaffs,
  accessToken,
  user,
  loading,
  setLoading,
  selectedRowKeys,
  setSelectedRowKeys,
  setSelectedStaff,
  setDeleted,
  setUpdated,
  deleted,
  updated,
}) => {
  const [column] = useState([
    {
      title: 'Name',
      dataIndex: 'fullName',
      ellipsis: true,
      align: 'center',
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      ellipsis: true,
      align: 'center',
      filters: [],
      onFilter: (value, record) => record.companyType.indexOf(value) === 0,
      render: (companyType) => <>{companyType ? companyType : 'N/A'}</>,
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      ellipsis: true,
      align: 'center',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'phoneNumber',
      ellipsis: true,
      align: 'center',
      render: (phoneNumber) => <>{phoneNumber ? phoneNumber : 'N/A'}</>,
    },
    {
      title: 'Department',
      dataIndex: 'department',
      ellipsis: true,
      align: 'center',
      render: (department) => <>{department ? department : 'N/A'}</>,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      ellipsis: true,
      align: 'center',
      filters: [
        {
          text: 'Admin',
          value: 'ADMIN',
        },
        {
          text: 'Complaint Handler',
          value: 'COMPLAINT_HANDLER',
        },
      ],
      onFilter: (value, record) => record.role.indexOf(value) === 0,
      render: (role) => <>{role ? Capitalize(role) : 'N/A'}</>,
    },
  ])

  //state to check if there are no staff
  // const [noStaff, setNoStaff] = useState(false)

  let rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedStaff(selectedRows[0])
      setSelectedRowKeys(selectedRowKeys)
    },
  }
  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      let res = []
      try {
        if (user?.company.type === CompanyTypes.cmab) {
          res = await getAllStaffs(accessToken)
        } else {
          res = await getStaffs(accessToken)
        }
        //incase there is no data fetched
        if(res.data?.length === 0 || res.data?.length === undefined){
          setStaffs([])
          setLoading(false)  
          // setNoStaff(true)
        }

        if (res.data?.data.length > 0) {
          let displayStaffs = [...res.data.data].map((staff) => {
            return {
              ...staff,
              companyName: staff?.company.name,
              companyType: staff?.company.type,
              key: staff.id,
            }
          })

          setStaffs(displayStaffs)
        }
        
        setLoading(false)
        setDeleted(false)
        setUpdated(false)
      } catch (error) {
        notification.error({
          message: 'Error',
          description: `${error.response.data.message}`,
        })
        setLoading(false)
      }
    }

    getData()
  }, [user, deleted, updated])
  //remove company column if user is not super admin
  let tableColumns = []
  let filters = []

  filters = staffs.map((staff) => {
    return {
      text: staff?.companyName,
      value: staff?.companyName,
    }
  })

  tableColumns = column.map((item, index) => {
    if (item.dataIndex === 'companyName') {
      return {
        ...item,
        filters,
      }
    }
    return item
  })
  //changed from user?.company.type to user?.type
  if (user?.company?.type !== 'CMAB') {
    tableColumns = tableColumns.filter(
      (header) =>
        header.dataIndex !== 'companyName' &&
        header.dataIndex !== 'companyType',
    )
  }
  //remove the details of the loggedIn user in the staffs array.
  //const _staffs = staffs.filter((staff) => staff.id !== (user?.id || user?.sub))
  return (
    <> 
      <Table
      rowSelection={{
        ...rowSelection,
      }}
      pagination={{ pageSize: 8 }}
      columns={tableColumns}
      dataSource={staffs}
      loading={loading}
      />
      {/* {noStaff && <p style={{color: "red", fontSize: "2rem"}}>""</p>} */}
    </>
  )
}

export default ViewStaffsTable
